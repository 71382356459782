import { useMemo } from "react";

import { useGetRawViewsQuery } from "@/apollo/types";

export const useRawViews = (
  options: Parameters<typeof useGetRawViewsQuery>[0] = {},
) => {
  const { data, loading } = useGetRawViewsQuery({
    skipPollAttempt: () => document.hidden,
    ...options,
  });
  const rawViews = useMemo(() => data?.getRawViews ?? [], [data]);
  return { rawViews, loading };
};

export const useRawViewsMap = (
  options: Parameters<typeof useGetRawViewsQuery>[0] = {},
) => {
  const { data, loading } = useGetRawViewsQuery({
    skipPollAttempt: () => document.hidden,
    ...options,
  });
  const rawViews = useMemo(() => {
    const views = data?.getRawViews ?? [];
    return new Map(views.map((view) => [view.viewId, view]));
  }, [data]);
  return { rawViews, loading };
};
