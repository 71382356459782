import { Maybe } from "@/apollo/types";
import Tooltip from "@/components/elements/Tooltip";
import { TextMuted } from "@/components/elements/Typography";
import { IntegrationLogoBox } from "@/integrations";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link } from "@tanstack/react-location";

const exists = (x: any) => !!x?.label;

export function ConnectorCell(props: {
  integrationId: string;
  connectionLabel?: string;
  destinationSchemaName?: Maybe<string>;
  tableNames: (string | undefined)[];
  scopings?: ({ label?: string; link?: string } | undefined)[];
}) {
  return (
    <div className="flex items-center space-x-4">
      <IntegrationLogoBox id={props.integrationId} size="sm" />
      <div className="flex flex-col">
        <div className="mb-1">
          <IntegrationLabel
            integrationId={props.integrationId}
            connectionLabel={props.connectionLabel}
            tableNames={props.tableNames}
          />
        </div>
        {props.destinationSchemaName && (
          <Tooltip content="Name of the directory in the data warehouse, where synced tables are stored in.">
            <TextMuted className="text-xs leading-none">
              {props.destinationSchemaName}
            </TextMuted>
          </Tooltip>
        )}
        {props.scopings && (
          <div className="flex flex-row items-center justify-start">
            <ChevronRightIcon className="mr-2 w-3 text-gray-400" />
            {props.scopings?.filter(exists).map((scoping) => (
              <div key={scoping?.label} className="flex items-center space-x-2">
                <div className="text-xs text-gray-400">
                  {scoping?.link ? (
                    <Link className="hover:text-gray-600" to={scoping?.link}>
                      {scoping?.label}
                    </Link>
                  ) : (
                    scoping?.label
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

const IntegrationLabel = (props: {
  integrationId: string;
  connectionLabel?: string;
  tableNames?: (string | undefined)[];
}) => {
  const displayableTables = (props.tableNames ?? []).filter(
    (elem) => elem !== undefined && elem !== null,
  );

  const showTableNames =
    ["google-sheets", "google-sheets-service-account", "ftp"].includes(
      props.integrationId,
    ) && displayableTables.length > 0;

  const formatTables = () => {
    if (displayableTables.length === 0) {
      return "";
    }

    const truncate = (str: string, n: number) => {
      if (str.length <= n) {
        return str;
      }
      return str.substring(0, n - 3) + "...";
    };

    if (displayableTables.length === 1) {
      return truncate(displayableTables[0] ?? "", 40);
    }
    const truncatedTablesList = truncate(displayableTables.join(", "), 40);
    return `(${displayableTables.length}) ${truncatedTablesList}`;
  };

  return (
    <div className="flex items-center space-x-1 font-medium text-gray-800 dark:text-white">
      <span>{props.connectionLabel || props.integrationId}</span>
      {showTableNames && (
        <>
          <ChevronRightIcon className="h-4 w-4" />
          <span title={displayableTables.join(", ")}>{formatTables()}</span>
        </>
      )}
    </div>
  );
};
