import { useConnectionQuery } from "@/apollo/types";
import { IntegrationLogo } from "@/integrations";

export function ConnectionBreadcrumb(props: { connectionId: string }) {
  const { data, loading } = useConnectionQuery({
    variables: {
      id: props.connectionId,
    },
  });
  if (loading) return null;
  if (data == null) return null;
  return (
    <div className="flex items-center gap-2">
      <IntegrationLogo id={data.connection.integrationId} className="h-4" />
      <span className="truncate">{data.connection.label}</span>
    </div>
  );
}
