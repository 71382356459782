import React from "react";
import rehypeParse from "rehype-parse";
import rehypeReact from "rehype-react";
import { unified } from "unified";

import {
  ListModelsQuery,
  MaterializationType,
  OrchestrationSchedulerType,
  useModelDocumentationQuery,
} from "@/apollo/types";
import { elements } from "@/components/elements/UnifiedElements";

import DraftBadge from "./components/DraftBadge";
import { ModelEmojiPicker } from "./components/EmojiPicker";
import { useModel } from "./hooks/useCurrentModel";
import { useModelFolderHierarchy } from "./hooks/useModelFolder";
import Lineage from "./lineage/Lineage";
import * as MaterializedTable from "./materialization/TableMaterilizationInfo";
import { isDraftModel } from "./utils/modelUtils";

export const ModelViewInfo = ({ modelId }: { modelId: string }) => {
  const { model } = useModel(modelId);

  const dependencies = model?.publishedQuery?.dependencies ?? [];
  const folderTrail = useModelFolderHierarchy(model?.folder?.id);

  const { data } = useModelDocumentationQuery({
    variables: {
      modelId: modelId,
    },
  });
  const documentation = data?.model.documentation;

  if (!model) return null;
  return (
    <div className="flex flex-col space-y-8 dark:text-white">
      <div className="space-y-4">
        <div className="flex items-center space-x-2">
          <div className="space-y-1">
            <div className="flex items-center space-x-1 text-left">
              {folderTrail.map((folder, i) => (
                <React.Fragment key={folder.id}>
                  {i > 0 && (
                    <span className="font-serif text-xs text-gray-300 dark:text-gray-500">
                      /
                    </span>
                  )}
                  <span className="table-cell align-text-top text-xs text-gray-500">
                    {folder.name}
                  </span>
                </React.Fragment>
              ))}
            </div>
            <div className="flex items-center text-lg text-gray-800 dark:text-white">
              <ModelEmojiPicker model={model} />
              <span>{model.name}</span>
              {isDraftModel(model) && <DraftBadge className="ml-2" />}
            </div>
          </div>
        </div>
      </div>
      <div className="space-y-8">
        <ModelViewItem
          title="Model documentation"
          emtpyText="This model doesn't have a documentation yet"
          isEmpty={!documentation}
        >
          <div className="text-sm">
            {
              unified()
                .use(rehypeParse, { fragment: true })
                .use(rehypeReact, elements)
                .processSync(documentation ?? "").result
            }
          </div>
        </ModelViewItem>
        {model.materializationType === MaterializationType.Table && (
          <LocalMaterializationJobInfo model={model} />
        )}
        <ModelViewItem
          title="Lineage Graph"
          emtpyText="This model doesn't have any dependencies"
          isEmpty={!dependencies?.length}
        >
          <div style={{ height: "50vh" }}>
            <Lineage focusedId={model.id} />
          </div>
        </ModelViewItem>
      </div>
    </div>
  );
};

export const LocalMaterializationJobInfo = (props: {
  model: ListModelsQuery["models"][0];
}) => {
  if (props.model.materializationType !== MaterializationType.Table)
    return null;
  if (props.model.orchestrationScheduler === OrchestrationSchedulerType.Global)
    return null;
  if (!props.model.localMaterializationJob) return null;

  return (
    <ModelViewItem
      title={
        <span>
          Materialized as table
          <span className="ml-2">
            <MaterializedTable.MaterializeJobStatus
              status={props.model.localMaterializationJob?.status}
            />
          </span>
        </span>
      }
    >
      <div className="flow-root text-sm">
        <div className="-m-4 flex flex-wrap">
          <div className="m-4">
            <div className="mb-0.5 text-xs font-medium">Next run</div>
            <div>
              <MaterializedTable.FormattedDateString
                value={props.model.localMaterializationJob.nextRun}
              />
            </div>
          </div>
          <div className="m-4">
            <div className="mb-0.5 text-xs font-medium">Latest update</div>
            <div>
              {props.model.localMaterializationJob.lastRun ? (
                <MaterializedTable.FormattedDateString
                  value={props.model.localMaterializationJob.lastRun}
                />
              ) : (
                "No job has run yet"
              )}
            </div>
          </div>
        </div>
      </div>
    </ModelViewItem>
  );
};

export const ModelViewItem = (
  props: React.PropsWithChildren<
    {
      title: React.ReactNode;
    } & (
      | {
          isEmpty?: boolean;
          emtpyText?: string;
        }
      | {
          isEmpty: true;
          emtpyText: string;
        }
    )
  >,
) => {
  return (
    <div className="w-full rounded-sm border bg-white dark:border-gray-700 dark:bg-gray-800">
      <div className="border-b p-4 text-sm font-semibold text-gray-700 dark:border-gray-700 dark:text-white">
        {props.title}
      </div>
      <div className="p-4">
        {props.isEmpty ? (
          <div className="text-xs text-gray-400">{props.emtpyText}</div>
        ) : (
          props.children
        )}
      </div>
    </div>
  );
};
