import { useCallback, useEffect, useState } from "react";

import { AvailableIntegrationFragment } from "@/apollo/types";
import { useCallbackRef } from "@/hooks/useCallbackRef";
import { useIsMobileSizeViewport } from "@/hooks/useIsMobileSizeViewport";
import { useIntegrationDocumentationResource } from "@/integrations";

export function useDocumentationPane(
  integration:
    | Pick<AvailableIntegrationFragment, "id" | "aliasMetadata">
    | undefined,
  options: {
    onClose?: () => void;
    onOpen?: () => void;
  },
) {
  const isMobileSizeViewport = useIsMobileSizeViewport();
  const [show, setShow] = useState(!isMobileSizeViewport);

  const onOpen = useCallbackRef(options.onOpen);
  const onClose = useCallbackRef(options.onClose);

  const documentationResource =
    useIntegrationDocumentationResource(integration);

  const isOpen = !!(show && integration != null && documentationResource.data);

  useEffect(() => {
    if (show && documentationResource.data) {
      onOpen();
    }
  }, [show, documentationResource.data, onOpen]);

  useEffect(() => {
    if (!show) {
      onClose();
    }
  }, [show, onClose]);

  useEffect(() => {
    if (!integration) {
      onClose();
    }
  }, [integration, onClose]);

  return {
    toggle: useCallback(
      (value?: boolean) =>
        setShow((prev) => {
          if (value === undefined) {
            return !prev;
          }
          return value;
        }),
      [],
    ),
    paneProps: {
      data: documentationResource.data,
      isLoading: documentationResource.loading,
      isOpen,
      onClose: () => setShow(false),
    },
  };
}
