import { useState } from "react";

import { IntegrationAbility } from "@/apollo/types";
import { ButtonCard } from "@/components/elements/ButtonCard";
import IconWithBG from "@/components/elements/IconWithBG";
import { AiAssistantIcon } from "@/components/icons/outline";
import { useConnectorRecommendations } from "@/features/connectors";
import cn from "@/helpers/classNames";
import { IntegrationLogoBox } from "@/integrations";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { ComponentProps } from "@tw-classed/react";

import DemoDataSetup from "../../DemoDataSetup";

export function CreateModelButton(props: ComponentProps<typeof ButtonCard>) {
  return (
    <ButtonCard
      {...props}
      className={cn("flex flex-1 flex-col p-10", props.className)}
    >
      <DocumentIcon className="m-5 h-8 w-8" />
      <div className="mt-4 grow text-center">
        <div className="text-md font-medium text-gray-700 dark:text-white">
          Create a SQL model
        </div>
        <div className="mt-1 text-sm text-muted-foreground">
          Start exploring your data by jumping into the code editor
        </div>
      </div>
    </ButtonCard>
  );
}

export function MeetEdButton(props: ComponentProps<typeof ButtonCard>) {
  return (
    <ButtonCard
      {...props}
      className={cn("flex flex-1 flex-col p-10", props.className)}
    >
      <AiAssistantIcon className="m-5 h-8 w-8" />
      <div className="mt-4 grow text-center">
        <div className="text-md font-medium text-gray-700 dark:text-white">
          Meet Ed, Your SQL Query Expert!
        </div>
        <div className="mt-1 text-sm text-muted-foreground">
          Ed, your AI assistant, is here to lend a hand with SQL queries
        </div>
      </div>
    </ButtonCard>
  );
}

export function ConnectFirstDataSourceButton(
  props: ComponentProps<typeof ButtonCard>,
) {
  const integrations = useConnectorRecommendations({
    ability: IntegrationAbility.EltSource,
    limit: 4,
  });

  return (
    <ButtonCard
      {...props}
      className={cn("flex flex-1 flex-col p-10", props.className)}
    >
      <div className="flex gap-3 opacity-90">
        {integrations.map((integration) => (
          <div key={integration.id} className="flex-1">
            <IntegrationLogoBox id={integration.id} />
          </div>
        ))}
        <IconWithBG
          icon={<span className="h-auto w-auto text-xs font-bold">150+</span>}
          className="bg-gray-200 dark:bg-gray-700"
        />
      </div>
      <div className="mt-4 grow text-center">
        <div className="text-md font-medium text-gray-700 dark:text-white">
          Connect Your First Data Source
        </div>
        <div className="mt-1 text-sm text-muted-foreground">
          Connect to over 150 data sources and start synchronizing your data to
          your data warehouse effortlessly.
        </div>
      </div>
    </ButtonCard>
  );
}

export function AddDemoDataButton(props: ComponentProps<typeof ButtonCard>) {
  const [addingDemoData, setAddingDemoData] = useState(false);
  return (
    <>
      <ButtonCard
        {...props}
        onClick={(e) => {
          props.onClick?.(e);
          setAddingDemoData(true);
        }}
        className={cn("flex flex-1 flex-col p-10", props.className)}
      >
        <div className="flex gap-3 opacity-90">
          <IntegrationLogoBox size="md" id="shopify" />
        </div>
        <div className="mt-4 grow text-center">
          <div className="text-md font-medium text-gray-700 dark:text-white">
            Start With Demo Data
          </div>
          <div className="mt-1 text-sm text-muted-foreground">
            Try out WELD with our Shopify demo data.
          </div>
        </div>
      </ButtonCard>
      <DemoDataSetup
        isOpen={addingDemoData}
        onClose={() => setAddingDemoData(false)}
      />
    </>
  );
}
