import { atom, useAtom, useAtomValue } from "jotai";
import { capitalize } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { IntegrationAbility } from "@/apollo/types";
import { IconButton } from "@/components/elements/Button";
import { ButtonCard } from "@/components/elements/ButtonCard";
import Tooltip from "@/components/elements/Tooltip";
import { useDataSourceSlideOver } from "@/components/modules/new-data-source-slideover";
import FieldLabel from "@/components/primitives/InputLabel";
import { useConnections } from "@/features/connectors";
import cn from "@/helpers/classNames";
import { useEltSyncs } from "@/hooks/useSync";
import { useIntegrations } from "@/integrations";
import {
  ChevronRightIcon,
  ChevronUpIcon,
  PlusIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline";

import CoreMetricsDescription from "./components/CoreMetricsDescription";
import CoreMetricsLineage from "./components/CoreMetricsLineage";
import { SetupHandoff } from "./components/SetupHandoff";
import { TemplateConnectorButton } from "./components/TemplateConnector";
import { useCurrentTemplate } from "./components/TemplatesProvider";

export const SelectedMetric = (props: {}) => {
  const { templateSyncs } = useTemplatesSyncs();

  return (
    <div className="absolute inset-0 flex flex-col overflow-hidden">
      <div className="flex items-center space-x-4 border-b p-4">
        <div className="flex items-center space-x-1">
          <SquaresPlusIcon className="h-4 w-4" />
          <div>Core metrics</div>
        </div>
        <ChevronRightIcon className="h-4 w-4" />
        <div className="font-medium">Marketing</div>
      </div>
      <div className="relative w-full flex-grow">
        <div className="absolute left-0 top-0 z-10 pt-10"></div>
        <div className="h-full w-full">
          <CoreMetricsLineage />
        </div>
      </div>
      <ConfigureTemplateBottomBar templateSyncs={templateSyncs} />
      <CoreMetricsDescription />
    </div>
  );
};

export default SelectedMetric;

export const useTemplatesSyncs = () => {
  const { eltSyncs, loading } = useEltSyncs();
  const template = useCurrentTemplate();

  return useMemo(() => {
    const templateSyncs: typeof eltSyncs = [];
    const otherSyncs: typeof eltSyncs = [];

    const templateEltSyncIds =
      template?.templateEltSync.map((templateElt) => templateElt.eltSyncId) ??
      [];

    eltSyncs.forEach((sync) => {
      const isTemplateSync = templateEltSyncIds.includes(sync.id);
      if (isTemplateSync) {
        templateSyncs.push(sync);
      } else {
        otherSyncs.push(sync);
      }
    });

    return {
      templateSyncs,
      otherSyncs,
      loading,
    };
  }, [eltSyncs, loading, template?.templateEltSync]);
};

const marketingIntegrations = [
  "Google Ads",
  "Facebook Ads",
  "LinkedIn Ads",
  "Twitter Ads",
  "Snapchat Ads",
  "Pinterest Ads",
  "Microsoft Ads",
  "x Ads",
  "TikTok Ads",
];

/**
 * For now we just hardcode the marketing integrations that we want to show.
 * TODO: switch this one with a version that gets the actual included integrations from the backend
 */
const useMarketingIntegrations = () => {
  const integrations = useIntegrations();
  return {
    integrations: integrations.filter(
      (integration) =>
        integration.abilities.includes(IntegrationAbility.EltSource) &&
        marketingIntegrations.includes(integration.name),
    ),
  };
};

const useTemplateIntegrations = (
  templateSyncs: ReturnType<typeof useTemplatesSyncs>["templateSyncs"],
) => {
  const { integrations } = useMarketingIntegrations();
  const { connections } = useConnections(IntegrationAbility.EltSource);

  return useMemo(() => {
    return [...integrations].sort((a, b) => {
      const aInTemplate = templateSyncs.find(
        (sync) => sync.sourceIntegrationId === a.id,
      );
      const bInTemplate = templateSyncs.find(
        (sync) => sync.sourceIntegrationId === b.id,
      );
      const aInConnections = connections.find(
        (connection) => connection.integrationId === a.id,
      );
      const bInConnections = connections.find(
        (connection) => connection.integrationId === b.id,
      );

      if (aInTemplate && !bInTemplate) {
        return -1;
      }
      if (!aInTemplate && bInTemplate) {
        return 1;
      }
      if (aInConnections && !bInConnections) {
        return -1;
      }
      if (!aInConnections && bInConnections) {
        return 1;
      }
      return 0;
    });
  }, [connections, integrations, templateSyncs]);
};

const highlightConnectorsAtom = atom<boolean>(false);
export const useSetHighlightConnectors = () => {
  const [, setHighlightConnectors] = useAtom(highlightConnectorsAtom);

  return useCallback(() => {
    setHighlightConnectors(true);
    setTimeout(() => {
      setHighlightConnectors(false);
    }, 1000);
  }, [setHighlightConnectors]);
};

const ConfigureTemplateBottomBar = (props: {
  templateSyncs: ReturnType<typeof useTemplatesSyncs>["templateSyncs"];
}) => {
  const highlightConnectors = useAtomValue(highlightConnectorsAtom);
  const template = useCurrentTemplate();
  const integrations = useTemplateIntegrations(props.templateSyncs);
  const [expanded, setExpanded] = useState(false);
  const { templateSyncs } = useTemplatesSyncs();
  const syncIsSetup = templateSyncs.length > 0;

  const { onOpen } = useDataSourceSlideOver();

  const size = expanded || !syncIsSetup ? "lg" : "sm";

  return (
    <div
      className={cn(
        "relative w-full overflow-auto border-t bg-white shadow-lg transition-all duration-500 dark:bg-gray-900",
        size === "lg" ? "max-h-[50vh] px-12 py-12" : "max-h-[24rem] px-8 py-4",
      )}
    >
      {size === "lg" && (
        <FieldLabel>{capitalize(template.name)} Data Sources</FieldLabel>
      )}
      <div className="flex w-full flex-wrap items-center gap-4 pr-12">
        {integrations.map((integration) => {
          return (
            <TemplateConnectorButton
              key={integration.id}
              integration={integration}
              highlight={highlightConnectors}
              size={size}
            />
          );
        })}
        {size === "lg" && (
          <ButtonCard
            className={cn(
              "group z-10 items-center rounded-sm transition-all",
              "w-64 justify-start space-x-3 rounded-sm p-5 text-left",
            )}
            onClick={() => onOpen()}
          >
            <div className="flex h-10 w-10 items-center justify-center rounded-sm border bg-gray-100">
              <PlusIcon className="h-6 w-6 text-gray-600 dark:text-gray-100" />
            </div>
            <div className="font-medium">View all data sources</div>
          </ButtonCard>
        )}
      </div>
      {size === "lg" && <SetupHandoff />}
      {syncIsSetup && (
        <Tooltip content={`Configure ${template.name.toLowerCase()} metrics`}>
          <IconButton
            size="md"
            className="absolute right-0 top-0 mr-4 mt-4"
            icon={
              <ChevronUpIcon
                className={cn(
                  "h-4 w-4 transition-all",
                  expanded ? "rotate-180" : "",
                )}
              />
            }
            onClick={() => setExpanded((p) => !p)}
          />
        </Tooltip>
      )}
    </div>
  );
};
