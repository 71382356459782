import { ComponentProps, forwardRef } from "react";

import { WeldPlan, useWorkspaceStatsQuery } from "@/apollo/types";
import { Alert, AlertIcon, AlertTitle } from "@/components/elements/Alert";
import AppearTransition from "@/components/elements/AppearTransition";
import { Button } from "@/components/elements/Button";
import { ChangePlanDialog } from "@/features/billing";
import {
  useCurrentPlan,
  useWeldPlanLimits,
  useWeldPlans,
} from "@/features/subscription";
import cn from "@/helpers/classNames";
import { useDisclosure } from "@/hooks/useDisclosure";
import { LinkWithSlug } from "@/routes";

const DefaultAlert = forwardRef<
  HTMLDivElement,
  {
    plan: WeldPlan;
    targetPlan?: WeldPlan;
    onClickConnections: () => void;
  } & ComponentProps<typeof Alert>
>((props, ref) => {
  const { plan, targetPlan, onClickConnections, ...rest } = props;
  const disclosure = useDisclosure();
  return (
    <Alert
      ref={ref}
      status="info"
      {...rest}
      className={cn("flex-col sm:flex-row", rest.className)}
    >
      <div className="flex items-center">
        <AlertIcon />
        <div>
          <AlertTitle className="mb-1">Connector Limit Reached</AlertTitle>
          You have reached the maximum number of connectors allowed by your
          current plan. Consider upgrading your plan to add more connectors.{" "}
          <div className="mt-1 flex divide-x divide-black/40">
            <div className="pr-2">
              <Button
                as={LinkWithSlug}
                to="/settings/connections"
                size="xs"
                variant="link"
                className="text-sm font-medium"
                onClick={() => onClickConnections()}
              >
                Manage Connections
              </Button>
            </div>
            <div className="pl-2">
              <Button
                as="a"
                href="https://weld.app/pricing"
                target="_blank"
                size="xs"
                variant="link"
                className="text-sm font-medium"
              >
                Learn more about our plans
              </Button>
            </div>
          </div>
          <ChangePlanDialog {...disclosure} size="lg" />
        </div>
      </div>
      <div className="mx-4 flex grow justify-end">
        <Button
          variant="ghost"
          colorScheme="primary"
          onClick={() => disclosure.onOpen()}
        >
          Upgrade
        </Button>
        <ChangePlanDialog {...disclosure} size="lg" />
      </div>
    </Alert>
  );
});

export const ConnectorsLimitAlert = forwardRef<
  HTMLDivElement,
  {
    onClickConnections?: () => void;
  } & ComponentProps<typeof Alert>
>((props, ref) => {
  const plan = useCurrentPlan();
  const plans = useWeldPlans();

  const { data: workspaceStatsData } = useWorkspaceStatsQuery({
    fetchPolicy: "cache-and-network",
  });
  const { isConnectorsLimitReached } = useWeldPlanLimits();
  if (!isConnectorsLimitReached) {
    return null;
  }

  const targetPlan = plans.getApplicablePlan({
    limitConnectors: {
      value: (workspaceStatsData?.workspaceStats.numConnections ?? 0) + 1,
    },
  });

  return (
    <AppearTransition>
      <DefaultAlert
        ref={ref}
        plan={plan}
        targetPlan={targetPlan?.plan}
        onClickConnections={() => props.onClickConnections?.()}
        {...props}
      />
    </AppearTransition>
  );
});
