import { SubscriptionStatus } from "@/apollo/types";
import { useSubscriptionStatus } from "@/features/subscription";
import { useEltSyncs } from "@/hooks/useSync";

import { UpgradeNowButton } from "../../UpgradeNowButton";
import { WorkspaceAlertResolver } from "../useWorkspaceAlerts";

export const useFreemiumResolver: WorkspaceAlertResolver = () => {
  const { status, trial } = useSubscriptionStatus();
  const hasSyncs = useEltSyncs().eltSyncs.length > 0;
  if (status !== SubscriptionStatus.Freemium || hasSyncs === false) {
    return [];
  }
  if (trial == null || trial.daysLeft > 7 || trial.isExpired) {
    return [];
  }
  return [
    {
      id: "freemium",
      status: "info",
      title: `Your free trial will end in ${trial.daysLeft} days`,
      message:
        "Upgrade now to ensure that your data syncs continue seamlessly without any disruptions.",
      action: <UpgradeNowButton size="xs" source="freemium banner" />,
      isDismissable: true,
    },
  ];
};
