import { useMemo } from "react";

import {
  AvailableIntegrationFragment,
  IntegrationAbility,
  useUserSignupInfoQuery,
} from "@/apollo/types";
import { useIntegrationsMap } from "@/integrations";
import { useCurrentUser } from "@/providers/UserProvider";

const defaultRecommended = ["hubspot-v2", "google-sheets", "mixpanel-source"];

const integrationsByBusinessDepartment = new Map([
  ["Data analytics", ["google-sheets", "stripe", "shopify"]],
  ["Engineering", ["postgresql", "aws-s3", "mysql"]],
  ["Marketing", ["linkedin-ads", "facebook-ads", "hubspot-v2"]],
  ["Operations", ["hubspot-v2", "google-sheets", "shopify"]],
  ["Finance", ["economic", "google-sheets", "shopify"]],
  ["Sales", ["hubspot-v2", "intercom", "google-sheets"]],
  ["Other", defaultRecommended],
]);

export const useRecommendedIntegrationsByBusinessDepartment = () => {
  const currentUser = useCurrentUser();
  const { data, loading } = useUserSignupInfoQuery({
    variables: {
      userId: currentUser.id,
    },
    skip: !currentUser,
  });

  const integrationsMap = useIntegrationsMap(IntegrationAbility.EltSource);

  let integrationIds = defaultRecommended;

  const jobTitle = data?.user.userSignupInfo?.jobTitle;
  if (jobTitle && integrationsByBusinessDepartment.has(jobTitle)) {
    integrationIds =
      integrationsByBusinessDepartment.get(jobTitle) ?? defaultRecommended;
  }
  return {
    integrations: useMemo(() => {
      return integrationIds.reduce<AvailableIntegrationFragment[]>(
        (acc, id) => {
          const integration = integrationsMap.get(id);
          if (integration) {
            acc.push(integration);
          }
          return acc;
        },
        [],
      );
    }, [integrationIds, integrationsMap]),
    loading,
  };
};
