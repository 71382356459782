import { ComponentProps } from "react";

import weldLogoDark from "@/assets/weld_box_dark.svg";
import weldLogoLight from "@/assets/weld_box_light.svg";
import { useDarkMode } from "@/providers/DarkModeProvider";

import { useIntegrationsMap } from "../IntegrationProvider";
import { useIntegration } from "../hooks/useIntegration";

export function IntegrationLogo({
  id,
  ...props
}: Omit<ComponentProps<"img">, "id"> & { id: string }) {
  const { src, alt } = useIntegrationLogo(id);
  return <img {...props} alt={alt} src={src} />;
}

export function useIntegrationLogo(id: string) {
  const integration = useIntegration(id);
  const { isDarkModeEnabled } = useDarkMode();

  let src = integration?.logo.default || weldLogoDark;
  if (isDarkModeEnabled) {
    src = integration?.logo.dark || integration?.logo.default || weldLogoLight;
  }

  return { src, alt: integration?.name ?? id };
}

export function useIntegrationLogoLazy() {
  const map = useIntegrationsMap();
  const { isDarkModeEnabled } = useDarkMode();

  const getIntegrationLogo = (id: string) => {
    const integration = map.get(id);
    let src = integration?.logo.default || weldLogoDark;
    if (isDarkModeEnabled) {
      src =
        integration?.logo.dark || integration?.logo.default || weldLogoLight;
    }
    return { src, alt: integration?.name ?? id };
  };
  return getIntegrationLogo;
}
