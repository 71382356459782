import { FullSyncTrigger, OperationMode } from "@/apollo/types";

export const isFullSync = (
  fullSyncTrigger: FullSyncTrigger | null | undefined,
  operationMode?: OperationMode,
) => {
  if (operationMode === OperationMode.Full) {
    return true;
  }
  return (
    fullSyncTrigger != null &&
    ![FullSyncTrigger.None, FullSyncTrigger.Lookback].includes(fullSyncTrigger)
  );
};
