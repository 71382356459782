import dayjs from "dayjs";

import { useMountEffect } from "@/hooks/useMountEffect";
import { useMixpanel } from "@/monitoring/mixpanel";

import { useAchivedDrafts, useDraftsAtom } from "../QueryEditor/useModelDraft";

export function useModelToolStorageMaintenance() {
  const [drafts, setDrafts] = useDraftsAtom();
  const archivedDrafts = useAchivedDrafts();
  const mixpanel = useMixpanel();

  useMountEffect(() => {
    const thirtyDaysAgo = dayjs().subtract(30, "days");
    const deletableDraftIds = archivedDrafts
      .filter((draft) => {
        return dayjs(draft.updatedAt).isBefore(thirtyDaysAgo);
      })
      .map((draft) => draft.id);

    if (deletableDraftIds.length > 0) {
      setDrafts((prevDrafts) => {
        return prevDrafts.filter(
          (draft) => !deletableDraftIds.includes(draft.id),
        );
      });
      mixpanel.track("Expired Model Drafts Deleted", {
        totalDrafts: drafts.length,
        archivedDrafts: archivedDrafts.length,
        deleteCount: deletableDraftIds.length,
      });
    }
  });
}
