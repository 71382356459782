import { useMemo } from "react";

import {
  MaterializationType,
  useListModelsQuery,
  useUpdateModelTableReferencesQuery,
} from "@/apollo/types";

import { isDraftModel } from "../utils/modelUtils";

export const useListModels = (
  autoUpdate?: boolean,
  options: Parameters<typeof useListModelsQuery>[0] = {},
) => {
  const { data: listModelsData, loading: loadingModels } =
    useListModelsQuery(options);

  const hasPendingUpdates = useMemo(() => {
    if (!autoUpdate) {
      return false;
    }
    return listModelsData?.models.some(
      (model) =>
        model.materializationType === MaterializationType.Table &&
        (!model.dwTable || model.dwTable.status === "RUNNING") &&
        !isDraftModel(model),
    );
  }, [autoUpdate, listModelsData]);

  //Poll for updates for table references, when there is a pending update
  //Apollo will automatically put the updated tables in the cache for the list of models.
  useUpdateModelTableReferencesQuery({
    pollInterval: 30_000,
    skipPollAttempt() {
      return document.hidden;
    },
    skip: !autoUpdate || !hasPendingUpdates,
  });

  const models = useMemo(() => {
    return listModelsData?.models || [];
  }, [listModelsData]);

  return { models, loadingModels };
};

export const useListPublishedModels = () => {
  const { data: listModelsData, loading: loadingModels } = useListModelsQuery();

  const publishedModels = useMemo(() => {
    return listModelsData?.models.filter((m) => !!m.publishedQuery) || [];
  }, [listModelsData]);

  return { publishedModels, loadingModels };
};
