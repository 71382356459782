import dayjs from "dayjs";
import React, { useMemo, useState } from "react";

import {
  HasDeletedModelsDocument,
  ListDeletedModelsQuery,
  ModelListItemFragmentDoc,
  useListDeletedModelsQuery,
  useRestoreDeletedModelMutation,
} from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import { SQLCodeDisplay } from "@/components/elements/CodeDisplay";
import { LoadingFull } from "@/components/elements/LoadingComponents";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import classNames from "@/helpers/classNames";
import { dateTimeFormat } from "@/helpers/dateFormat";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount } from "@/providers/account";
import { useNavigate } from "@tanstack/react-location";

import ReadOnlyModelEditor from "../../QueryEditor/ReadOnlyModelEditor";
import {
  DraftModel,
  useDrafts,
  useRestoreDraft,
} from "../../QueryEditor/useModelDraft";

type Props = {
  open: boolean;
  onClose: () => void;
};

const ModelsArchiveModal = (props: Props) => {
  const [tab, setTab] = useState<"drafts" | "models">("drafts");

  return (
    <Modal
      size="lg"
      isOpen={props.open}
      onClose={() => {
        props.onClose();
      }}
    >
      <ModalCloseButton />
      <ModalHeader>Archive</ModalHeader>
      <ModalBody>
        <div className="flex items-center space-x-6">
          <TabButton
            onClick={() => {
              setTab("drafts");
            }}
            active={tab === "drafts"}
          >
            Unsaved Drafts
          </TabButton>
          <TabButton
            onClick={() => {
              setTab("models");
            }}
            active={tab === "models"}
          >
            Models
          </TabButton>
        </div>
        <div className="py-2">
          {tab === "drafts" ? (
            <DraftArchive onClose={props.onClose} />
          ) : (
            <ModelsArchive onClose={props.onClose} />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

const DraftArchive = (props: { onClose: () => void }) => {
  const { slug } = useCurrentAccount();

  const { archived } = useDrafts();

  const [showCode, setShowCode] = useState<DraftModel | null>(null);

  const restoreDraft = useRestoreDraft();
  const navigate = useNavigate();

  const sortedDrafts = useMemo(() => {
    return archived.sort((a, b) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    });
  }, [archived]);

  const toast = useToast();

  const handleRestoreDraft = (draft: DraftModel) => {
    const { newOrder } = restoreDraft(draft.id);
    navigate({ to: `/${slug}/editor/draft/${draft.id}` });
    toast(
      "Draft restored",
      `Draft restored as "Unsaved draft${
        newOrder > 0 ? ` (${newOrder})` : ""
      }"`,
      "success",
    );
    props.onClose();
  };
  return (
    <>
      <p className="mb-2 text-sm">
        Unsaved drafts are archived after 24 hours of inactivity and can be
        restored here. If not restored, they will be permanently deleted after
        30 days.
      </p>
      <div className="flex items-center border-b py-2 text-xs dark:border-gray-500 dark:text-gray-500">
        <div className="w-1/4 pr-4">Updated at</div>
        <div className="w-1/4 pr-4">Created at</div>
        <div className="w-1/4 pr-4">Code preview</div>
      </div>

      <div className="max-h-96 space-y-1 overflow-auto">
        {sortedDrafts.map((draft) => {
          return (
            <div key={draft.id} className="flex items-center py-2 text-xs">
              <div className="w-1/4 pr-4">
                {dayjs(draft.updatedAt).format(dateTimeFormat)}
              </div>
              <div className="w-1/4 pr-4">
                {dayjs(draft.createdAt).format(dateTimeFormat)}
              </div>
              <div className="w-1/4 truncate pr-4">{draft.weldSql}</div>

              <div className="flex w-1/4 items-center justify-end">
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => setShowCode(draft)}
                >
                  view code
                </Button>
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => handleRestoreDraft(draft)}
                >
                  restore draft
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      <Modal size="lg" isOpen={!!showCode} onClose={() => setShowCode(null)}>
        {showCode && (
          <>
            <ModalCloseButton />
            <ModalHeader>
              Code for {showCode.name} created on{" "}
              {dayjs(showCode.createdAt).format(dateTimeFormat)}
            </ModalHeader>
            <ModalBody className="max-h-96 overflow-auto">
              <SQLCodeDisplay
                sql={showCode.weldSql.length > 0 ? showCode.weldSql : " "}
              />
            </ModalBody>
          </>
        )}
      </Modal>
    </>
  );
};

const ModelsArchive = (props: { onClose: () => void }) => {
  const { slug } = useCurrentAccount();

  const [openModel, setOpenModel] = useState<
    ListDeletedModelsQuery["deletedModels"][0] | null
  >(null);
  const navigate = useNavigate();

  const [restoreModel, restoreModelMutation] = useRestoreDeletedModelMutation({
    refetchQueries: [HasDeletedModelsDocument],
    update: (cache, { data }) => {
      if (!data?.restoreDeletedModel) return;
      cache.modify({
        fields: {
          models(existingModels = []) {
            const newModelRef = cache.writeFragment({
              data: data.restoreDeletedModel,
              fragment: ModelListItemFragmentDoc,
              fragmentName: "ModelListItem",
            });
            return [...existingModels, newModelRef];
          },
        },
      });
    },
  });

  const { data: { deletedModels } = {}, loading } = useListDeletedModelsQuery();

  const sortedModels = useMemo(() => {
    if (!deletedModels) return [];
    return [...deletedModels].sort((a, b) => {
      return (
        new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
      );
    });
  }, [deletedModels]);

  const handleRestoreModel = async (modelId: string) => {
    if (restoreModelMutation.loading) return;

    await restoreModel({ variables: { modelId } });

    navigate({ to: `/${slug}/editor/${modelId}` });
    props.onClose();
  };
  return (
    <>
      <div className="flex items-center border-b py-2 text-xs dark:border-gray-500 dark:text-gray-500">
        <div className="w-1/4 pr-4">Model name</div>
        <div className="w-1/4 pr-4">Created at</div>
        <div className="w-1/4 pr-4"></div>
      </div>

      <div className="max-h-96 space-y-1 overflow-auto">
        {loading && (
          <div className="relative h-96">
            <LoadingFull />
          </div>
        )}
        {sortedModels.map((model) => {
          const createdDateTimeString = dayjs(model.createdAt).format(
            dateTimeFormat,
          );
          return (
            <div key={model.id} className="flex items-center py-2 text-xs">
              <div className="w-1/4 pr-4">
                {model.name.length > 0 ? model.name : createdDateTimeString}
              </div>
              <div className="w-1/4 pr-4">{createdDateTimeString}</div>
              <div className="w-1/4 pr-4"></div>
              <div className="flex w-1/4 items-center justify-end">
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => setOpenModel(model)}
                >
                  view code
                </Button>
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => handleRestoreModel(model.id)}
                >
                  restore model
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        isOpen={!!openModel}
        size="lg"
        onClose={() => {
          setOpenModel(null);
        }}
      >
        <div>
          {openModel && (
            <>
              <ModalCloseButton />
              <ModalHeader>
                Code for {openModel.name} created on{" "}
                {dayjs(openModel.createdAt).format(dateTimeFormat)}
              </ModalHeader>
              <ModalBody className="h-96 overflow-auto">
                <ReadOnlyModelEditor modelId={openModel.id} />
              </ModalBody>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ModelsArchiveModal;

function TabButton(
  props: React.PropsWithChildren<{
    active: boolean;
    onClick: () => void;
  }>,
) {
  return (
    <button
      onClick={props.onClick}
      className={classNames(
        props.active
          ? "cursor-default border-b border-primary text-primary"
          : "cursor-pointer border-b border-transparent hover:border-primary hover:text-primary dark:text-white dark:hover:text-primary",
        "py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 dark:focus:ring-offset-gray-800",
      )}
    >
      {props.children}
    </button>
  );
}
