import { motion } from "framer-motion";

export default function AppearTransition({
  children,
}: {
  children: JSX.Element;
}) {
  const appearVariants = {
    hidden: { opacity: 0, y: 0, scale: 0.95 },
    visible: { opacity: 1, y: 0, scale: 1 },
  };
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={appearVariants}
      transition={{ duration: 0.3, ease: "easeOut" }}
    >
      {children}
    </motion.div>
  );
}
