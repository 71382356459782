import React, { createContext, useContext, useMemo } from "react";

import {
  AvailableIntegrationFragment,
  IntegrationAbility,
  useAvailableIntegrationsQuery,
  useDestinationIntegrationsQuery,
} from "@/apollo/types";

import { hasAbility } from "./utils";

export const IntegrationContext = createContext<
  AvailableIntegrationFragment[] | null
>(null);

export const useIntegrations = (
  ability?: IntegrationAbility,
): AvailableIntegrationFragment[] => {
  const integrations = useContext(IntegrationContext);
  if (integrations === null) {
    throw new Error(
      "useIntegrations must be used within a IntegrationsProvider",
    );
  }
  return useMemo(() => {
    const list =
      ability === undefined
        ? integrations
        : integrations.filter((i) => hasAbility(i, ability));
    return list;
  }, [integrations, ability]);
};

export const useIntegrationsMap = (
  ability?: IntegrationAbility,
): Map<string, AvailableIntegrationFragment> => {
  const integrations = useIntegrations(ability);
  return useMemo(() => {
    return new Map(integrations.map((x) => [x.id, x]));
  }, [integrations]);
};

export const IntegrationsProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { data, loading } = useAvailableIntegrationsQuery();
  if (loading) return null;
  return (
    <IntegrationContext.Provider value={data?.availableIntegrations ?? []}>
      {children}
    </IntegrationContext.Provider>
  );
};

export const DestinationsIntegrationsProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { data, loading } = useDestinationIntegrationsQuery();
  if (loading) return null;
  return (
    <IntegrationContext.Provider value={data?.destinationIntegrations ?? []}>
      {children}
    </IntegrationContext.Provider>
  );
};
