import { useFlags } from "launchdarkly-react-client-sdk";
import { ComponentProps, useEffect } from "react";

import { PlanName } from "@/apollo/types";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import { UpgradeNowButton } from "@/components/modules/UpgradeNowButton";
import { WELD_EXISTING_CUSTOMER_CALL_URL } from "@/constants/external-urls/hubspot";
import { WELD_PRICING_URL } from "@/constants/external-urls/weld-website";
import { formatPlanName } from "@/features/billing";
import { useCurrentPlan } from "@/features/subscription";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useCurrentAccount } from "@/providers/account";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/16/solid";

export function CoreProPlanDiscontinuationDialog(
  props: ComponentProps<typeof Modal>,
) {
  const mixpanel = useMixpanel();
  const { showCoreProPlanDiscontinuationAnnouncement } = useFlags();

  useEffect(() => {
    if (props.isOpen) {
      mixpanel.track("Core_Pro Plan Discontinuation Dialog - Opened");
    }
  }, [props.isOpen, mixpanel]);

  const account = useCurrentAccount();

  const weldPlan = useCurrentPlan();
  if (
    ![PlanName.Core, PlanName.Pro].includes(weldPlan.name) ||
    !showCoreProPlanDiscontinuationAnnouncement
  ) {
    return null;
  }

  return (
    <Modal {...props} centerOnScreen={false}>
      <ModalHeader>🔔 Important Update from Weld</ModalHeader>
      <ModalCloseButton />
      <ModalBody className="pb-5">
        <div className="space-y-5">
          <p>Hi {account.name || account.slug}!</p>
          <p>
            A quick reminder that the{" "}
            <strong>
              {formatPlanName(weldPlan.name)} plan will be discontinued on May
              1st, 2025.
            </strong>
          </p>
          <p>
            We encourage you to explore our updated plans to ensure a smooth
            transition. Check out our{" "}
            <a
              href={WELD_PRICING_URL}
              className="inline-flex items-center text-blue-600 underline underline-offset-2 dark:text-blue-500"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                mixpanel.track(
                  "Core_Pro Plan Discontinuation Dialog - Pricing Page Clicked",
                );
              }}
            >
              pricing page
              <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
            </a>{" "}
            for detailed information.
          </p>
          <p>
            We value you as a customer and want to make sure you have the best
            experience with Weld. If you need a <strong>tailored plan</strong>{" "}
            to fit your needs, we’re happy to discuss options that work for you.
          </p>
          <p>
            📅{" "}
            <a
              href={WELD_EXISTING_CUSTOMER_CALL_URL}
              className="inline-flex items-center text-blue-600 underline underline-offset-2 dark:text-blue-500"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                mixpanel.track(
                  "Core_Pro Plan Discontinuation Dialog - Book a Call Clicked",
                );
              }}
            >
              Book a Call
              <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
            </a>{" "}
            with us — we're here to help!
          </p>

          <p>
            If you're ready, upgrade today to continue enjoying our services
            without interruption.
          </p>
          <div className="flex justify-center">
            <UpgradeNowButton
              source="Core_Pro Plan Discontinuation Dialog"
              onDialogClose={props.onClose}
            />
          </div>

          <p>Thank you for your continued support!</p>

          <p>
            <strong>— The Weld Team</strong>
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
}
