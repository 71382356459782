import { useCallback, useMemo } from "react";

import {
  AvailableIntegrationFragment,
  IntegrationAbility,
} from "@/apollo/types";
import { useIntegrationFlagHelper } from "@/integrations";

import { createConnectorOption } from "./createConnectorOption";
import { createEmptyIntegration } from "./helpers";
import { useAvailableIntegrations } from "./useAvailableIntegrations";

const weldSampleData = createConnectorOption(
  createEmptyIntegration("weld-sample-data", "WELD Sample Data", [
    IntegrationAbility.EltSource,
  ]),
  { elt: "show", reverseElt: "hide" },
);

export function useConnectorOptions(params: {
  dwIntegration: AvailableIntegrationFragment;
}) {
  const integrations = useAvailableIntegrations(params.dwIntegration);
  const getIntegrationFlags = useGetIntegrationFlags();
  const options = useMemo(() => {
    return integrations
      .map((integration) => {
        const flags = getIntegrationFlags(integration.id);
        return createConnectorOption(integration, flags);
      })
      .concat(weldSampleData);
  }, [integrations, getIntegrationFlags]);
  return options;
}

function useGetIntegrationFlags() {
  const integrationFlagHelper = useIntegrationFlagHelper();
  return useCallback(
    (integrationId: string) => {
      return {
        elt: integrationFlagHelper.checkFlag(
          integrationId,
          IntegrationAbility.EltSource,
        ),
        reverseElt: integrationFlagHelper.checkFlag(
          integrationId,
          IntegrationAbility.ActivateDestination,
        ),
      };
    },
    [integrationFlagHelper],
  );
}
