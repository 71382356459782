import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useThrottledCallback } from "use-debounce";

import {
  InitialSyncStatus,
  InitialSyncStatusResponse,
  useInitialSyncsStatusQuery,
} from "@/apollo/types";
import { useInterval } from "@/hooks/useInterval";
import { useSocketEvent } from "@/socket/SocketContext";

export function useInitialSyncsStatus(
  syncIds: string[],
  options: {
    refreshInterval: number;
    onSyncsCompleted: (syncs: InitialSyncStatusResponse[]) => void;
  },
) {
  const { data, refetch } = useInitialSyncsStatusQuery({
    fetchPolicy: "network-only",
    variables: {
      syncIds,
    },
    skip: syncIds.length === 0,
    pollInterval: options.refreshInterval,
    skipPollAttempt: () => document.hidden,
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const completedSyncs = data.initialSyncsStatus.filter((sync) => {
        return (
          sync.status === InitialSyncStatus.Completed ||
          sync.status === InitialSyncStatus.Failed
        );
      });
      if (completedSyncs.length > 0) {
        options.onSyncsCompleted(completedSyncs);
      }
    },
  });

  const throttledRefetch = useThrottledCallback(refetch, 5_000, {
    leading: true,
    trailing: true,
  });

  useSocketEvent("elt-sync:updated", {
    onMessage: (message) => {
      if (!syncIds.includes(message.payload.syncId)) return;
      throttledRefetch();
    },
  });

  const syncs = data?.initialSyncsStatus ?? [];
  const runningSyncs = syncs.filter((sync) => {
    return [InitialSyncStatus.Scheduled, InitialSyncStatus.Running].includes(
      sync.status,
    );
  });
  return runningSyncs;
}

const isSyncRecentlyCompleted = (sync: InitialSyncStatusResponse) => {
  return (
    sync.finishedAt != null &&
    dayjs(sync.finishedAt).isAfter(dayjs().subtract(1, "minute"))
  );
};

export function useCompletedSyncsLastMinute(
  syncs: InitialSyncStatusResponse[],
) {
  const [recentSyncs, setRecentSyncs] = useState(syncs);

  useInterval(
    () => {
      setRecentSyncs(syncs.filter(isSyncRecentlyCompleted));
    },
    syncs.length > 0 ? 60_000 : null,
  );

  useEffect(() => {
    if (syncs.length > 0) {
      setRecentSyncs(syncs.filter(isSyncRecentlyCompleted));
    }
  }, [syncs]);

  return recentSyncs;
}
