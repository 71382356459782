import {
  AvailableIntegrationFragment,
  IntegrationAbility,
} from "@/apollo/types";

export const hasAbility = (
  integration: AvailableIntegrationFragment | null,
  ...abilities: IntegrationAbility[]
) => {
  if (integration === null) return false;
  return abilities.every((x) => integration.abilities.includes(x));
};

export const isCustomConnector = (integrationId: string) =>
  integrationId.startsWith("custom-integration");
