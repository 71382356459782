import { useMemo } from "react";

import { IntegrationAbility, useConnectionsQuery } from "@/apollo/types";
import { useIntegrationFlagHelper, useIntegrationsMap } from "@/integrations";

export function useConnections(type: IntegrationAbility) {
  const { data, loading, refetch } = useConnectionsQuery({
    fetchPolicy: "cache-and-network",
  });
  const connections = data?.connections;
  const integrations = useIntegrationsMap();
  const integrationFlagHelper = useIntegrationFlagHelper();

  return useMemo(() => {
    const goodFlagValues = ["show", "beta"];
    return {
      loading,
      connections: (connections ?? []).filter((c) => {
        const i = integrations.get(c.integrationId);
        if (!i) return false;
        const flagValue = integrationFlagHelper.checkFlag(
          c.integrationId,
          type,
        );
        return i.abilities.includes(type) && goodFlagValues.includes(flagValue);
      }),
      refetch,
    };
  }, [
    connections,
    integrations,
    loading,
    type,
    integrationFlagHelper,
    refetch,
  ]);
}
