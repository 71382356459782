import dayjs from "dayjs";

import { InitialSyncStatus, InitialSyncStatusResponse } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import { FormatRelativeTime } from "@/components/elements/FormatRelativeTime";
import Tooltip from "@/components/elements/Tooltip";
import { useViewDataSourceSlideOver } from "@/components/modules/view-data-source-slideover";
import { IntegrationLogo, useIntegrationsMap } from "@/integrations";
import {
  formatBytes,
  formatDate,
  formatDuration,
  formatRowCount,
} from "@/shared/formatters";

export function InitialSyncItem(props: InitialSyncStatusResponse) {
  const viewDataSource = useViewDataSourceSlideOver();
  const integrations = useIntegrationsMap();
  return (
    <div className="flex w-full gap-2">
      <div className="grow space-y-1">
        <div className="flex items-center gap-2">
          <div className="flex w-full items-center gap-2">
            <IntegrationLogo id={props.integrationId} className="h-4" />
            <span className="truncate">
              {integrations.get(props.integrationId)?.name}
            </span>
          </div>
        </div>
        <div className="space-y-2 pl-6 text-sm">
          <ul>
            {props.startedAt && (
              <li>
                Sync start:{" "}
                <Tooltip
                  content={formatDate(props.startedAt, {
                    dateStyle: "short",
                    timeStyle: "long",
                  })}
                  placement="right"
                >
                  <FormatRelativeTime
                    date={dayjs.min(dayjs(), dayjs(props.startedAt))}
                  />
                </Tooltip>
              </li>
            )}
            {props.finishedAt && (
              <li>
                Sync end:{" "}
                <Tooltip
                  content={formatDate(props.finishedAt, {
                    dateStyle: "short",
                    timeStyle: "long",
                  })}
                  placement="right"
                >
                  <FormatRelativeTime date={props.finishedAt} />
                </Tooltip>
              </li>
            )}
            {props.duration != null && (
              <li>
                Duration:{" "}
                {formatDuration(Math.floor(props.duration / 1000) * 1000)}
              </li>
            )}
            <li>
              Status:{" "}
              <StatusText
                status={props.status}
                isChunkedSync={props.isChunkedSync}
                hasError={props.tableStats.failed > 0}
              />
            </li>
          </ul>
          {props.status !== InitialSyncStatus.Scheduled && (
            <ul>
              <li>
                Tables: {props.tableStats.completed}/{props.tableStats.total}{" "}
                {props.tableStats.total === 1 ? `table` : `tables`} completed
              </li>
              <li>
                Rows synced: {formatRowCount(props.tableStats.recordsSynced)}
              </li>
              <li>Data volume: {formatBytes(props.tableStats.bytesSynced)}</li>
            </ul>
          )}
        </div>
      </div>
      <div className="ml-auto">
        <Button
          size="sm"
          onClick={() => viewDataSource.onOpen({ syncId: props.syncId })}
        >
          Inspect
        </Button>
      </div>
    </div>
  );
}

function StatusText({
  status,
  isChunkedSync,
  hasError,
}: {
  status: InitialSyncStatus;
  isChunkedSync: boolean;
  hasError: boolean;
}) {
  if (status === InitialSyncStatus.Scheduled) {
    return <span className="font-medium text-foreground">Queued</span>;
  }
  if (status === InitialSyncStatus.Running) {
    if (hasError) {
      return (
        <span className="font-medium text-red-600">Running with errors</span>
      );
    }
    return <span className="font-medium text-blue-500">Running</span>;
  }
  if (status === InitialSyncStatus.Failed) {
    return <span className="font-medium text-red-600">Failed</span>;
  }
  return (
    <span className="font-medium text-green-600">
      {isChunkedSync ? "Partially Completed" : "Completed"}
    </span>
  );
}
