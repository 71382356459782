import { removeStorageItems } from "@/utils//storage/storage-utils";

const DEPRECATED_STORAGE_KEYS = [
  "currentAccountId",
  "recent-opened-models",
  "currentModelMode",
  "sidebarItemSettings",
  "historySidebarState",
  "isDarkModeEnabled",
  "weld-current-tab",
  "weld-tabs",
  "weld:essential-plan-discontinuation-banner",
  "weld:essential-plan-discontinuation-dialog",
];

export function removedDeprecatedStorageItems() {
  removeStorageItems(DEPRECATED_STORAGE_KEYS);
}
