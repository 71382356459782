import { useMemo } from "react";

import { IntegrationAbility } from "@/apollo/types";
import { useConnections } from "@/features/connectors";

export const useGoogleSheetsDestinations = () => {
  const data = useConnections(IntegrationAbility.ActivateDestination);

  return useMemo(() => {
    return {
      ...data,
      connections: data.connections.filter(
        (x) => x.integrationId === "google-sheets",
      ),
    };
  }, [data]);
};
