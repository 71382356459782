import dayjs, { ConfigType } from "dayjs";

import { FullSyncTrigger, SourceStreamSnapshotFragment } from "@/apollo/types";
import useNow from "@/hooks/useNow";
import {
  formatBytes,
  formatDate,
  formatDuration,
  formatRowCount,
} from "@/shared/formatters";

import { formatFullSyncTrigger, formatOperationMode } from "../lib/formatters";
import { SyncStatusText } from "./SyncStatusText";

export function SyncHistoryInfo<
  T extends Omit<SourceStreamSnapshotFragment, "__typename">,
>({ runDetails, liveDuration }: { runDetails: T; liveDuration?: boolean }) {
  if (!runDetails || !runDetails.startedAt) {
    return null;
  }
  return (
    <ul className="space-y-1">
      <li>
        Status: <SyncStatusText details={runDetails} />
      </li>
      <li>
        Sync start:{" "}
        {formatDate(runDetails.startedAt, {
          dateStyle: "short",
          timeStyle: "long",
        })}
      </li>
      {runDetails.finishedAt && (
        <>
          <li>
            Sync end:{" "}
            {formatDate(runDetails.finishedAt, {
              dateStyle: "short",
              timeStyle: "long",
            })}
          </li>
          <li>
            Duration:{" "}
            <DurationText
              startedAt={runDetails.startedAt}
              finishedAt={runDetails.finishedAt}
            />
          </li>
        </>
      )}
      {liveDuration && runDetails.finishedAt == null && (
        <li>
          Elapsed: <ElapsedTime startedAt={runDetails.startedAt} />
        </li>
      )}
      {runDetails.recordsSynced != null && runDetails.recordsSynced !== 0 && (
        <>
          <li>Rows synced: {formatRowCount(runDetails.recordsSynced)}</li>
          <li>Data volume: {formatBytes(runDetails.bytesSynced)}</li>
        </>
      )}
      {runDetails.operationMode != null && (
        <li>
          Mode:{" "}
          {formatOperationMode(
            runDetails.operationMode,
            runDetails.fullSyncTrigger,
          )}
        </li>
      )}
      {runDetails.fullSyncTrigger != null &&
        runDetails.fullSyncTrigger !== FullSyncTrigger.None && (
          <li>
            Full sync trigger:{" "}
            {formatFullSyncTrigger(runDetails.fullSyncTrigger)}
          </li>
        )}
      {runDetails.details && <li>Details: {runDetails.details}</li>}
    </ul>
  );
}

function DurationText(props: {
  startedAt: ConfigType;
  finishedAt: ConfigType;
}) {
  const { startedAt, finishedAt } = props;
  return (
    <span>
      {formatDuration(dayjs(finishedAt).valueOf() - dayjs(startedAt).valueOf())}
    </span>
  );
}

function ElapsedTime(props: { startedAt: string }) {
  const now = useNow(1000);
  return <DurationText startedAt={props.startedAt} finishedAt={now} />;
}
