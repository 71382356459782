import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  CellProps,
  Column,
  IdType,
  Row as RowType,
  SortingRule,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowState,
  useSortBy,
  useTable,
} from "react-table";

import {
  ConnectionsDocument,
  ConnectionsWithUsageSummaryDocument,
  ConnectionsWithUsageSummaryQuery,
  IntegrationAbility,
  useRefreshDestinationMutation,
  useRemoveConnectionMutation,
} from "@/apollo/types";
import { ButtonPrimitive } from "@/components/elements/Button";
import ConfirmDeleteModal from "@/components/elements/ConfirmDeleteModal";
import IntegrationCellTitle from "@/components/elements/IntegrationCellTitle";
import { List } from "@/components/elements/List";
import MenuItem from "@/components/elements/MenuItem";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/elements/Table";
import Tooltip from "@/components/elements/Tooltip";
import { TextMuted } from "@/components/elements/Typography";
import TableMenu from "@/components/modules/TableMenu";
import { Input } from "@/components/primitives/input";
import { useFilteredNotifications } from "@/features/notifications";
import cn from "@/helpers/classNames";
import { dateTimeFormat } from "@/helpers/dateFormat";
import useDeleteItem from "@/hooks/useDeleteItem";
import { useIntegrationsMap } from "@/integrations";
import { useToast } from "@/providers/ToastProvider";
import {
  ArrowUpIcon,
  ArrowsUpDownIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

import { ConnectionsNotifications } from "./ConnectionNotifications";

dayjs.extend(relativeTime);

type ConnectionData = ConnectionsWithUsageSummaryQuery["connectors"][0];

const globalFilterFn = (
  rows: Array<RowType<ConnectionData>>,
  columnIds: Array<IdType<ConnectionData>>,
  filterValue: any,
): Array<RowType<ConnectionData>> => {
  if (
    filterValue === undefined ||
    (typeof filterValue === "string" && filterValue.trim() === "")
  ) {
    return rows;
  }
  return rows.filter((row) => {
    const d = row.original;
    const fields = [d.label, d.integrationId];
    return fields
      .filter((value): value is string => typeof value === "string")
      .some((value) => value.toLowerCase().includes(filterValue.toLowerCase()));
  });
};

const ConnectionsTable = (props: {
  data: ConnectionData[];
  onRowClick: (
    row: ConnectionData,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => void;
}) => {
  const integrations = useIntegrationsMap();

  const columns = useMemo<Column<ConnectionData>[]>(
    () => [
      {
        Header: "Connector",
        Cell: ({
          value: integrationId,
        }: CellProps<ConnectionData, ConnectionData["integrationId"]>) => {
          return (
            <IntegrationCellTitle
              integrationId={integrationId}
              connectionLabel={integrations.get(integrationId)?.name}
              size="xs"
            />
          );
        },
        accessor: "integrationId",
        sortType: (rowA, rowB) => {
          const getSortValue = (row: ConnectionData) => {
            const val = integrations.get(row.integrationId)?.name;
            return val || row.integrationId;
          };
          return getSortValue(rowA.original).localeCompare(
            getSortValue(rowB.original),
          );
        },
      },
      {
        Header: "Name",
        Cell: ({
          row,
          value: label,
        }: CellProps<ConnectionData, ConnectionData["label"]>) => {
          if (label) return <div>{label}</div>;
          return (
            <Tooltip content="Name not set, using ID instead">
              <div className="text-gray-300">{row.original.integrationId}</div>
            </Tooltip>
          );
        },
        accessor: "label",
        sortType: (rowA, rowB) => {
          return rowA.original.label.localeCompare(rowB.original.label);
        },
      },
      {
        Header: "Created",
        Cell: ({
          value: createdAt,
        }: CellProps<ConnectionData, ConnectionData["label"]>) => {
          return (
            <Tooltip content={dayjs(createdAt).format(dateTimeFormat)}>
              <div>{dayjs(createdAt).fromNow()}</div>
            </Tooltip>
          );
        },
        accessor: "createdAt",
        sortType: (rowA, rowB) =>
          dayjs(rowA.original.createdAt).unix() -
          dayjs(rowB.original.createdAt).unix(),
      },
      {
        Header: "Updated",
        Cell: ({
          value: updatedAt,
        }: CellProps<ConnectionData, ConnectionData["updatedAt"]>) => {
          return (
            <Tooltip content={dayjs(updatedAt).format(dateTimeFormat)}>
              <div>{dayjs(updatedAt).fromNow()}</div>
            </Tooltip>
          );
        },
        accessor: "updatedAt",
        sortType: (rowA, rowB) =>
          dayjs(rowA.original.updatedAt).unix() -
          dayjs(rowB.original.updatedAt).unix(),
      },
      {
        accessor: "usageSummary",
        Header: "Sync Count",
        Cell: ({
          value: usageSummary,
        }: CellProps<ConnectionData, ConnectionData["usageSummary"]>) => {
          return (
            <Tooltip
              content={
                usageSummary.totalSyncs === 0 ? (
                  "This connector is not in use"
                ) : (
                  <List className="p-0">
                    {usageSummary.numEltSyncs > 0 && (
                      <List.Item className="p-0">
                        {usageSummary.numEltSyncs} Data Source{" "}
                        {usageSummary.numEltSyncs === 1 ? "sync" : "syncs"}
                      </List.Item>
                    )}
                    {usageSummary.numRevEltSyncs > 0 && (
                      <List.Item className="p-0">
                        {usageSummary.numRevEltSyncs} Reverse ETL{" "}
                        {usageSummary.numRevEltSyncs === 1 ? "sync" : "syncs"}
                      </List.Item>
                    )}
                  </List>
                )
              }
            >
              <div>
                {usageSummary.totalSyncs === 0 ? (
                  <TextMuted>-</TextMuted>
                ) : (
                  usageSummary.totalSyncs
                )}
              </div>
            </Tooltip>
          );
        },
        sortType: (rowA, rowB) =>
          rowA.original.usageSummary.totalSyncs -
          rowB.original.usageSummary.totalSyncs,
      },
      {
        id: "status",
        Header: "Status",
        Cell: ({ row }: CellProps<ConnectionData, ConnectionData["id"]>) => {
          return <Status connection={row.original} />;
        },
        width: 100,
        maxWidth: 100,
        disableSortBy: true,
      },
      {
        id: "actions",
        accessor: "id",
        Header: "",
        Cell: ({
          row,
          value: id,
        }: CellProps<ConnectionData, ConnectionData["id"]>) => {
          return (
            <div className="flex justify-end">
              <ConnectionMenu
                abilities={
                  integrations.get(row.original.integrationId)?.abilities || []
                }
                connectionId={id}
              />
            </div>
          );
        },
        disableSortBy: true,
      },
    ],
    [integrations],
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const [sorting, setSorting] = useState<SortingRule<ConnectionData>[]>([
    {
      id: "createdAt",
      desc: true,
    },
  ]);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    setGlobalFilter: setTableGlobalFilter,
    state: { sortBy },
  } = useTable<ConnectionData>(
    {
      columns,
      data: props.data,
      initialState: {
        sortBy: sorting,
        hiddenColumns: ["createdAt", "active"],
      },
      globalFilter: globalFilterFn,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowState,
  );

  useEffect(() => {
    setSorting(sortBy);
  }, [sortBy]);

  // Debounce the search term
  useEffect(() => {
    const handler = setTimeout(() => {
      setTableGlobalFilter(searchTerm);
    }, 300); // 300ms delay

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, setTableGlobalFilter]);

  return (
    <div className="flex flex-col gap-8">
      <ConnectionsNotifications connections={props.data} />
      <div className="w-full max-w-screen-xl">
        <div className="flex items-center gap-4 pb-4">
          <div className="relative flex w-full max-w-sm items-center">
            <Input
              ref={inputRef}
              placeholder="Filter data sources..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="md"
              className="w-full pl-10 text-sm"
              autoFocus
            />
            <div className="absolute left-0 top-0 z-10 flex h-full items-center px-2">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 dark:text-gray-400" />
            </div>
            {searchTerm.trim().length > 0 && (
              <div className="absolute right-0 top-0 z-10 flex h-full items-center px-2">
                <ButtonPrimitive
                  className="opacity-50 hover:opacity-100"
                  onClick={() => {
                    setSearchTerm("");
                    inputRef.current?.focus();
                  }}
                >
                  <XMarkIcon className="h-5 w-5" />
                </ButtonPrimitive>
              </div>
            )}
          </div>
        </div>
        {/* <DefaultTable
          onRowClick={handleRowClick}
          columns={columns}
          data={props.data}
          isSortable
        /> */}

        <Table {...getTableProps()}>
          <TableHeader>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      {...header.getHeaderProps(
                        header.getSortByToggleProps({
                          style: {
                            maxWidth: header.maxWidth,
                            minWidth: header.minWidth,
                            width: header.width,
                          },
                        }),
                      )}
                    >
                      <div
                        className={cn("flex items-center whitespace-nowrap", {
                          "font-medium": header.isSorted,
                        })}
                      >
                        {header.render("Header")}
                        {header.canSort && (
                          <div
                            className={cn(
                              "ml-1 inline-flex h-4 w-4 text-gray-500 opacity-40",
                              {
                                "opacity-100": header.isSorted,
                              },
                            )}
                          >
                            {header.isSorted ? (
                              <ArrowUpIcon
                                className={cn({
                                  "rotate-180 transition-transform":
                                    header.isSortedDesc,
                                })}
                              />
                            ) : (
                              <ArrowsUpDownIcon />
                            )}
                          </div>
                        )}
                      </div>
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  onClick={(e) => props.onRowClick(row.original, e)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps({
                          style: {
                            maxWidth: cell.column.maxWidth,
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                        })}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

const ConnectionMenu = ({
  connectionId,
  abilities,
}: {
  connectionId: string;
  abilities: IntegrationAbility[];
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleDelete = useDeleteItem({
    title: "connection",
    variables: {
      connectionId,
    },
    mutation: useRemoveConnectionMutation,
    refetchQueries: [
      {
        query: ConnectionsDocument,
      },
      {
        query: ConnectionsWithUsageSummaryDocument,
      },
    ],
  });
  return (
    <>
      <ConfirmDeleteModal
        title="connection"
        onConfirm={() => {
          return handleDelete();
        }}
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
      />
      <TableMenu>
        <>
          {abilities.includes(IntegrationAbility.ActivateDestination) && (
            <RefreshDestinationMenuItem connectionId={connectionId} />
          )}

          <MenuItem text="Delete" onClick={() => setShowConfirmModal(true)} />
        </>
      </TableMenu>
    </>
  );
};

const RefreshDestinationMenuItem = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const toast = useToast();

  const [refreshDestination] = useRefreshDestinationMutation({
    onError(error) {
      toast("Connection not refreshed", error.message, "error");
    },
    update: (cache) => {
      cache.modify({
        id: cache.identify({
          __typename: "Query",
        }),
        fields: {
          destinationProperties(_, { DELETE }) {
            return DELETE;
          },
          findAllAvailableOperations(_, { DELETE }) {
            return DELETE;
          },
        },
      });
    },

    onCompleted() {
      toast(
        "Connection refreshed",
        "Your connection has succesfully been refreshed.",
        "success",
      );
    },
    refetchQueries: [
      { query: ConnectionsDocument, variables: { type: "destination" } },
    ],
  });

  const onRefreshDestination = () => {
    refreshDestination({
      variables: { connectionId: connectionId },
    });
  };

  return (
    <MenuItem text="Refresh object types" onClick={onRefreshDestination} />
  );
};

const Status = (props: { connection: ConnectionData }) => {
  const { notifications } = useFilteredNotifications({
    connectionIds: [props.connection.id],
  });

  if (notifications.count) {
    return (
      <div className="flex">
        <ExclamationCircleIcon className="mr-1 h-5 w-5 shrink-0 text-red-600 dark:text-white" />
        <span>Suspended</span>
      </div>
    );
  }

  return (
    <div className="flex">
      <CheckCircleIcon className="mr-1 h-5 w-5 shrink-0 text-green-600 dark:text-white" />
      <span>Healthy</span>
    </div>
  );
};

export default ConnectionsTable;
