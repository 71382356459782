import produce from "immer";
import { useEffect } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormState,
} from "react-hook-form";

import ConnectionSettings from "@/components/modules/ConnectionSettings";
import { useIntegration } from "@/integrations";
import StartDateConfig from "@/pages/EltSyncs/modules/StartDateConfig";

import { useStepsContext } from "../../../stepper/useSteps";
import { Content, Footer } from "../../components/Layout";
import { NextButton, PrevButton } from "../../components/NavButton";
import { useDataSourceStepContext } from "../../contexts/DataSourceStepsContext";
import { useDataSourceStateContext } from "../../step-state";

export function SourceSettingsStepContainer() {
  const { onNextStep } = useStepsContext();
  const { state, setState } = useDataSourceStateContext();

  const formMethods = useForm({
    mode: "onChange",
    defaultValues: {
      sourceSettings: state.sourceSettings.value.sourceSettings,
      startDate: state.sourceSettings.value.startDate,
      __isValidHack__: true,
    },
  });

  const { isValid, isDirty } = useFormState({
    control: formMethods.control,
  });

  useEffect(() => {
    setState(
      produce((state) => {
        state.sourceSettings.isDirty = isDirty;
      }),
    );
  }, [isDirty, setState]);

  const sourceSettings = formMethods.watch("sourceSettings");
  const { connection, connectionSettings } = useDataSourceStepContext();
  const sourceIntegration = useIntegration(connection?.integrationId);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit((data) => {
          setState(
            produce((state) => {
              state.sourceSettings = {
                value: {
                  sourceSettings: data.sourceSettings,
                  startDate: data.startDate,
                },
                isValid: true,
                isDirty: false,
              };
            }),
          );
          onNextStep();
        })}
        className="flex h-full w-full flex-col"
      >
        <Content>
          <div className="flex flex-col gap-8">
            <input
              type={"hidden"}
              {...formMethods.register("__isValidHack__", {
                validate: (value) => {
                  return value || "Invalid";
                },
              })}
            />
            {connection && (
              <Controller
                name="sourceSettings"
                render={({ field }) => (
                  <ConnectionSettings
                    connectionSettings={connectionSettings}
                    onChange={(settings) => {
                      field.onChange({
                        ...field.value,
                        ...settings,
                      });
                    }}
                    onChangeValid={(isValid) => {
                      if (
                        formMethods.getValues("__isValidHack__") !== isValid
                      ) {
                        formMethods.setValue("__isValidHack__", isValid, {
                          shouldValidate: true,
                        });
                      }
                    }}
                    initialValues={{
                      ...sourceSettings,
                      connectionId: connection?.id,
                    }}
                  />
                )}
              />
            )}
            {sourceIntegration?.config?.supportsStartDate && (
              <Controller
                name="startDate"
                render={({ field }) => (
                  <StartDateConfig
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value?.toString() || null);
                    }}
                  />
                )}
              />
            )}
          </div>
        </Content>
        <Footer className="flex-row-reverse">
          <NextButton isDisabled={!isValid} />
          <PrevButton />
        </Footer>
      </form>
    </FormProvider>
  );
}
