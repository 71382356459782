import { useMemo } from "react";

import { useEltSyncsQuery } from "@/apollo/types";

export const useEltSync = (eltSyncId: string) => {
  const { data, loading } = useEltSyncsQuery();

  return useMemo(() => {
    const eltSync = data?.eltSyncs.find((eltSync) => eltSync.id === eltSyncId);
    return { loading, eltSync };
  }, [data?.eltSyncs, eltSyncId, loading]);
};

export const useEltSyncs = (options?: { pollInterval?: number }) => {
  const { data, loading } = useEltSyncsQuery({
    ...options,
    skipPollAttempt: () => document.hidden,
  });

  return useMemo(
    () => ({ eltSyncs: data?.eltSyncs ?? [], loading }),
    [data, loading],
  );
};
