import dayjs from "dayjs";
import { useMemo } from "react";

import { ListModelFoldersQuery, ListModelsQuery } from "@/apollo/types";
import { useModelFolderHierarchy } from "@/pages/ModelTool/hooks/useModelFolder";

import { useModel } from "./useCurrentModel";

type Folder = ListModelFoldersQuery["listModelFolders"][number];
type Model = ListModelsQuery["models"][number];

//take a model and return the full path with all parent folders in the format:
//["parentFolder", "folder", "modelName"]
export function useFullModelPath(model?: Model) {
  const modelFolderHierarchy = useModelFolderHierarchy(model?.folder?.id);
  const modelName = !model
    ? "Unknown model"
    : model.name || dayjs(model.createdAt).format("YYYY/MM/DD HH:mm");

  return useMemo(() => {
    if (model == null) return [modelName];
    if (modelFolderHierarchy.length === 0) return [modelName];
    return modelFolderHierarchy.map((x) => x.name).concat(modelName);
  }, [model, modelName, modelFolderHierarchy]);
}

//take a folderId and return the full path with all parent folders in the format:
//["parentFolder", "folder", "modelName"]
export function useFullFolderPath(folderId?: string) {
  const modelFolderHierarchy = useModelFolderHierarchy(folderId);

  return useMemo(() => {
    if (!folderId) return [];
    return modelFolderHierarchy.map((x) => x.name);
  }, [folderId, modelFolderHierarchy]);
}

type Renderer<T extends any> = (
  model: Partial<Model> | undefined | null,
  modelDisplayName: string,
  folders: Folder[],
) => T;

const defaultRenderer: Renderer<string> = (
  model,
  modelDisplayName,
  folders,
) => {
  return folders
    .map((x) => x.name)
    .concat(modelDisplayName)
    .join(".");
};

/**
 * Renders a model and its folder hierarchy.
 * Default renderer will produce a string with a dotted path, i.e. "foo.bar.baz"
 * A custom renderer can be supplied to take complete control of the rendering, i.e.
 * as JSX elements,
 */
export function useFullModelPathRenderer<T = string>(
  model?: Pick<Model, "name" | "folder"> | null,
  renderer?: Renderer<T>,
): T {
  const folders = useModelFolderHierarchy(model?.folder?.id);

  const modelName = model?.name ? model.name : "Unknown model";

  return useMemo(() => {
    if (typeof renderer === "function") {
      return renderer(model, modelName, folders) as T;
    }
    return defaultRenderer(model, modelName, folders) as unknown as T;
  }, [folders, model, modelName, renderer]);
}

export function FolderPathRenderer(props: {
  folderId?: string;
  children: (folder: Folder) => React.ReactNode;
}) {
  const folders = useModelFolderHierarchy(props.folderId);
  return <>{folders.map(props.children)}</>;
}

export const useModelDisplayName = (modelId: string) => {
  const { model } = useModel(modelId);
  const fullPath = useFullModelPath(model);
  return useMemo(() => {
    const folderPath = fullPath.slice(0, fullPath.length - 1);
    const modelName = fullPath[fullPath.length - 1];
    return {
      modelName,
      pathName: folderPath.length > 0 ? folderPath.join(".") : undefined,
    };
  }, [fullPath]);
};

export const ModelDisplayName = (props: {
  modelId: string;
  className?: string;
}) => {
  const { modelName, pathName } = useModelDisplayName(props.modelId);
  return (
    <span className={props.className}>
      {pathName ? `${pathName}.${modelName}` : modelName}
    </span>
  );
};
