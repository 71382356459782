import { useChartTheme } from "@/features/charts/themes";
import { useIntegrationLogoLazy } from "@/integrations";

export default function SyncChartCategoryTick(props: {
  x?: number;
  y?: number;
  textAnchor?: string;
  stroke?: string;
  payload?: any;
  width?: number;
  index?: number;
  getLabel?: (index: number) => string;
  getIntegrationId?: (index: number) => string;
}) {
  const chartTheme = useChartTheme("stats");

  const { y, payload, index, width, getLabel, getIntegrationId } = props;

  const getIntegrationLogo = useIntegrationLogoLazy();

  if (payload == null || y == null || index == null || width == null) {
    return <g></g>;
  }

  const integrationId =
    typeof getIntegrationId === "function"
      ? getIntegrationId(index)
      : payload.value;

  const logo = getIntegrationLogo(integrationId);

  const label =
    typeof getLabel === "function" ? getLabel(index) : payload.value;
  let truncatedLabel = label;

  const labelX = 30;
  const labelFontSize = 12;

  if (props.width) {
    const labelMaxWidth = props.width - labelX - 10;
    const approximateCharWidth = labelFontSize / 1.6;
    const maxLabelChars = Math.floor(labelMaxWidth / approximateCharWidth);
    if (label.length > maxLabelChars) {
      truncatedLabel = label.slice(0, maxLabelChars) + "…";
    }
  }
  return (
    <g transform={`translate(${0},${y})`}>
      <g>
        <image x={3} y={-7} width={16} height={16} href={logo.src} />
      </g>
      <text
        x={30}
        dominantBaseline="central"
        textAnchor="start"
        fill={chartTheme.text}
        fontSize={labelFontSize}
      >
        <tspan>{truncatedLabel}</tspan>
        <title>{label}</title>
      </text>
    </g>
  );
}
