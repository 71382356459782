import {
  AvailableIntegrationFragment,
  DevStatus,
  IntegrationAbility,
} from "@/apollo/types";
import { FeatureMode } from "@/integrations";

export type ConnectorOption = {
  value: string;
  label: string;
  integration: AvailableIntegrationFragment;
  isBeta?: boolean;
  isAccessRequestable?: boolean;
  isPreview?: boolean;
  isCommunity?: boolean;
  isSoonConnector?: boolean;
  // `isAlias` is used to indicate that this connector is an alias for another connector
  isAlias?: boolean;
  logo?: {
    default?: string;
    dark?: string;
  };
  abilities: IntegrationAbility[];
  metadata?: AvailableIntegrationFragment;
};

export function createConnectorOption(
  integration: AvailableIntegrationFragment,
  flags: {
    elt?: FeatureMode;
    reverseElt?: FeatureMode;
  } = {},
): ConnectorOption {
  const isBeta =
    flags.elt === "beta" ||
    flags.reverseElt === "beta" ||
    [integration.eltSource, integration.activateDestination].some(
      (x) => x?.status === DevStatus.Beta,
    );

  const isPreview = flags.elt === "preview" || flags.reverseElt === "preview";

  const enabledAbilities: IntegrationAbility[] = [];
  if (integration.eltSource && flags.elt !== "hide") {
    enabledAbilities.push(IntegrationAbility.EltSource);
  }
  if (integration.activateDestination && flags.reverseElt !== "hide") {
    enabledAbilities.push(IntegrationAbility.ActivateDestination);
  }

  const isSoonConnector =
    integration.eltSource?.tags.includes("Soon") ||
    integration.activateDestination?.tags.includes("Soon");

  const isCommunityConnector =
    integration.eltSource?.tags.includes("Community") ||
    integration.activateDestination?.tags.includes("Community");

  return {
    value: integration.id,
    label: integration.name,
    isCommunity: isCommunityConnector,
    isAlias: !!integration.aliasMetadata?.id,
    isBeta,
    isPreview,
    isSoonConnector,
    isAccessRequestable: isSoonConnector,
    logo: {
      default: integration.logo?.default || undefined,
      dark: integration.logo?.dark || undefined,
    },
    abilities: enabledAbilities,
    integration,
    metadata: integration,
  };
}
