import { isEqual } from "lodash";
import { useMemo } from "react";
import { FieldError, useForm } from "react-hook-form";

import { OrchestrationSchedulerType } from "@/apollo/types";
import { CronDisplayReadable } from "@/components/elements/CronDisplayReadable";
import { InformationIconTooltip } from "@/components/elements/Tooltip";
import ListboxDropdown, {
  DropdownItem,
} from "@/components/elements/input/ListboxDropdown";
import { LocalScheduleIcon } from "@/components/icons/OrchestrateIcon";
import FieldLabel from "@/components/primitives/InputLabel";
import { Input } from "@/components/primitives/input";
import { useListOrchestrationWorkflows } from "@/hooks/useListOrchestrationWorkflows";
import { PlusIcon } from "@heroicons/react/24/outline";

import { OrchestrationIcon } from "../icons/outline";
import { AdvancedSyncScheduler } from "./WeldSyncScheduler";

type OrchestrationConfig = {
  orchestrationScheduler?: OrchestrationSchedulerType | undefined;
  orchestrationWorkflowId?: string | undefined;
};

export const useSchedulerOptions = (configuration: {
  allowNewWorkflow?: boolean;
  allowNoSchedule?: boolean;
}) => {
  const { workflows } = useListOrchestrationWorkflows(false);

  const scheduleOptions: DropdownItem<OrchestrationConfig>[] = useMemo(() => {
    const options: DropdownItem<OrchestrationConfig>[] = [
      {
        id: "local",
        title: "Independent",
        description: "Update on an independent schedule.",
        icon: <LocalScheduleIcon className="h-6 w-6 flex-none" />,
        value: {
          orchestrationScheduler: OrchestrationSchedulerType.Local,
          orchestrationWorkflowId: undefined,
        },
      },

      ...workflows.map((workflow) => {
        return {
          id: workflow.id,
          title: workflow.name,
          description: <CronDisplayReadable cron={workflow.cronExpression} />,
          icon: <OrchestrationIcon className="h-6 w-6 flex-none" />,
          value: {
            orchestrationScheduler: OrchestrationSchedulerType.Global,
            orchestrationWorkflowId: workflow.id,
          },
        };
      }),
    ];
    if (configuration.allowNoSchedule) {
      options.push({
        id: "no-schedule",
        title: "No scheduler",
        description: "Create table once, and don't update it afterwards..",
        icon: <div className="h-4 w-4 flex-none rounded-full bg-gray-200" />,
        value: {
          orchestrationScheduler: undefined,
          orchestrationWorkflowId: undefined,
        },
      });
    }
    if (configuration.allowNewWorkflow)
      options.push({
        id: "new-workflow",
        title: "New Orchectration",
        description:
          "Create a new orchestration workflow, to orchestrate the creation of this table.",
        icon: <PlusIcon className="h-4 w-4 flex-none transform" />,
        value: {
          orchestrationScheduler: OrchestrationSchedulerType.Global,
          orchestrationWorkflowId: undefined,
        },
      });
    return options;
  }, [
    configuration.allowNewWorkflow,
    configuration.allowNoSchedule,
    workflows,
  ]);
  return scheduleOptions;
};

export const SchedulerSelecter = (props: {
  configuration: {
    allowNewWorkflow?: boolean;
    allowNoSchedule?: boolean;
  };
  orchestrationScheduler?: OrchestrationSchedulerType | undefined;
  orchestrationWorkflowId?: string | undefined;
  onUpdate: (config: OrchestrationConfig, addNew?: boolean) => void;
  onUpdateNewWorkflowName?: (name: string) => void;
  onUpdateNewWorkflowCron?: (cron?: string) => void;
  newWorkflowCron?: string;
  hideLabel?: true;
}) => {
  const scheduleOptions = useSchedulerOptions(props.configuration);

  const selectedOption = useMemo(() => {
    const config: OrchestrationConfig = {
      orchestrationScheduler: props.orchestrationScheduler,
      orchestrationWorkflowId: props.orchestrationWorkflowId,
    };
    if (config.orchestrationScheduler === OrchestrationSchedulerType.Local) {
      return scheduleOptions.find((o) => o.id === "local");
    }
    return scheduleOptions.find((o) => isEqual(o.value, config));
  }, [
    props.orchestrationScheduler,
    props.orchestrationWorkflowId,
    scheduleOptions,
  ]);
  const isAddingWorkflow = selectedOption?.id === "new-workflow";

  const form = useForm<{ newWorkflowName: string }>({
    defaultValues: {
      newWorkflowName: "",
    },
  });

  //Don't display the selecter if there are no options to select
  if (scheduleOptions.length < 2) return null;

  return (
    <>
      {!props.hideLabel && (
        <FieldLabel>
          <div className="flex items-center space-x-2">
            <span>Select scheduler</span>
            <InformationIconTooltip content="The selected scheduler can always be changed later." />
          </div>
        </FieldLabel>
      )}
      <ListboxDropdown<OrchestrationConfig>
        options={scheduleOptions}
        selected={selectedOption}
        onSelect={(option) => {
          const addNew = option.id === "new-workflow";
          if (option.value) props.onUpdate(option.value, addNew);
        }}
        placeholder="Select scheduler that will update this model."
      />
      {isAddingWorkflow && (
        <>
          <div>
            <FieldLabel>Orchestration Name</FieldLabel>
            <Input
              autoFocus
              autoComplete="off"
              {...form.register("newWorkflowName", {
                required: true,
                onChange: (e) => {
                  form.setValue("newWorkflowName", e.target.value);
                  form.trigger("newWorkflowName");
                  props.onUpdateNewWorkflowName?.(e.target.value);
                },
              })}
            />
            {form.formState.errors.newWorkflowName && (
              <ValidationErrorDisplay
                error={form.formState.errors.newWorkflowName}
              />
            )}
          </div>
          <div>
            <FieldLabel>Schedule</FieldLabel>
            <AdvancedSyncScheduler
              onChange={(cron) => {
                props.onUpdateNewWorkflowCron?.(cron);
              }}
              cron={props.newWorkflowCron}
            />
          </div>
        </>
      )}
    </>
  );
};

const ValidationMessages: Record<string, string> = {
  required: "This field is required",
};

export const ValidationErrorDisplay = (props: { error: FieldError }) => {
  const errorMsg = ValidationMessages[props.error.type];

  return (
    <div className="text-xs text-red-500">{errorMsg || props.error.type}</div>
  );
};
