import { IntegrationAbility } from "@/apollo/types";

import { useRecommendedIntegrationsByBusinessDepartment } from "./useRecommendedIntegrationsByBusinessDepartment";

export function useConnectorRecommendations(options: {
  ability: IntegrationAbility;
  limit?: number;
}) {
  // const { integrations: onboardingConnectionPreferences } =
  //   useOnboardingConnectionPreferences(options.ability);

  const { integrations } = useRecommendedIntegrationsByBusinessDepartment();

  // let integrations = uniqBy(
  //   onboardingConnectionPreferences.concat(integrationsByBusinessDepartment),
  //   (x) => x.id,
  // );
  return integrations.slice(0, options.limit ?? 3);
}
