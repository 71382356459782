import {
  useCurrentSubscriptionStateLazyQuery,
  useCurrentUserWithAccountsLazyQuery,
  useCurrentWeldPlanLazyQuery,
  useNotificationsLazyQuery,
  usePlanLimitsStatusLazyQuery,
  useStripeSubscriptionLazyQuery,
  useUpcomingInvoiceLazyQuery,
} from "@/apollo/types";
import { useCurrentAccount } from "@/providers/account";

export function useRevalidateSubscriptionCache() {
  const account = useCurrentAccount();

  const [fetchUserWithAccounts] = useCurrentUserWithAccountsLazyQuery({
    fetchPolicy: "network-only",
    context: {
      skipXAccountIdHeader: true,
    },
  });

  const [fetchStripeSubscription] = useStripeSubscriptionLazyQuery({
    fetchPolicy: "network-only",
    variables: { slug: account.slug },
  });

  const [fetchCurrentWeldPlan] = useCurrentWeldPlanLazyQuery({
    fetchPolicy: "network-only",
  });

  const [fetchPlanLimitsStatus] = usePlanLimitsStatusLazyQuery({
    fetchPolicy: "network-only",
  });

  const [fetchCurrentSubscriptionState] = useCurrentSubscriptionStateLazyQuery({
    fetchPolicy: "network-only",
  });

  const [fetchNotifications] = useNotificationsLazyQuery({
    fetchPolicy: "network-only",
  });

  const [fetchUpcomingInvoice] = useUpcomingInvoiceLazyQuery({
    fetchPolicy: "network-only",
  });

  return async () => {
    const [subscriptionResult] = await Promise.all([
      // The detailed Stripe Subscription data, i.e. Stripe Customer and Subscription details
      fetchStripeSubscription(),
      // The Weld plan definitions, i.e. limits, add-ons etc.
      fetchCurrentWeldPlan(),
      // The current plan limits status, i.e. current usage, limits etc.
      fetchPlanLimitsStatus(),
      // Refetch the user data, i.e. accounts with settings, such as block ELT syncs etc.
      fetchUserWithAccounts(),
      // The current subscription status (removes Freemium/Trial Expired banners etc.)
      fetchCurrentSubscriptionState(),
      // The current notifications that may have been triggered/removed by creating the subscription
      fetchNotifications(),
    ]);

    const customer = subscriptionResult.data?.accountBySlug.stripeCustomer;
    const subscription =
      subscriptionResult.data?.accountBySlug.stripeSubscription
        ?.stripeSubscriptionDetails;
    if (customer && subscription && subscription.cancelDate == null) {
      // The upcoming invoice data, i.e. the next invoice amount, due date etc.
      await fetchUpcomingInvoice({
        variables: {
          customerId: customer.id,
          subscriptionId: subscription.id,
        },
      });
    }
  };
}
