import { Suspense } from "react";
import { FormProvider } from "react-hook-form";
import { LocationGenerics } from "routes";

import {
  ConnectionWithCredentialsQuery,
  useConnectionWithCredentialsQuery,
  useIntegrationConfigurationSuspenseQuery,
} from "@/apollo/types";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import Centered from "@/components/elements/layout/Centered";
import { useUpdateConnection } from "@/integrations";
import { useToast } from "@/providers/ToastProvider";
import { useMatch, useNavigate } from "@tanstack/react-location";

import ConnectionForm, {
  ConnectionFormSubmitButton,
  useConnectionForm,
} from "./ConnectionForm/ConnectionForm";

export default function EditConnectionPageWrapper() {
  const {
    params: { connectionId },
  } = useMatch<LocationGenerics>();

  const { data } = useConnectionWithCredentialsQuery({
    variables: {
      id: connectionId,
    },
  });
  if (data?.connection == null) {
    return <LoadingSpinner />;
  }
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <EditConnectionPage connection={data.connection} />
    </Suspense>
  );
}

function EditConnectionPage({
  connection,
}: {
  connection: ConnectionWithCredentialsQuery["connection"];
}) {
  const { data } = useIntegrationConfigurationSuspenseQuery({
    variables: {
      id: connection.integrationId,
    },
  });
  const integration = data.integrationConfiguration;

  const navigate = useNavigate();
  const toast = useToast();

  const formMethods = useConnectionForm(integration, {
    defaultValues: {
      ...connection.credentials,
      label: connection.label,
    },
  });

  const { loading, updateConnection } = useUpdateConnection(
    connection.id,
    integration,
    {
      onSuccess() {
        toast(
          "Connection updated",
          "The connection has been succesfully updated",
          "success",
        );
        navigate({ to: ".." });
      },
      onError(e) {
        toast("Connection not updated", e.message, "error");
      },
    },
  );

  return (
    <Centered>
      <FormProvider {...formMethods}>
        <form
          id="integration-form"
          onSubmit={formMethods.handleSubmit((data) => updateConnection(data))}
        >
          <div className="w-full max-w-lg">
            <ConnectionForm
              integration={integration}
              isEditMode
              isLoading={loading}
            />
            <div className="mt-8">
              <ConnectionFormSubmitButton
                integration={integration}
                isLoading={loading}
                isEditMode
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </Centered>
  );
}
