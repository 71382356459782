import { ComponentProps } from "react";

import cn from "@/helpers/classNames";

import { IntegrationLogo } from "./IntegrationLogo";

type Size = "1xs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

const sizeClasses: Record<Size, string> = {
  "1xs": "w-4 h-4",
  xs: "w-6 h-6",
  sm: "w-8 h-8",
  md: "w-10 h-10",
  lg: "w-12 h-12",
  xl: "w-14 h-14",
  "2xl": "w-16 h-16",
};

export function IntegrationLogoBox(
  props: Omit<ComponentProps<"div">, "id"> & {
    id: string;
    size?: Size;
  },
) {
  const { id, size, ...restProps } = props;
  const containerClass = sizeClasses[size ?? "md"];
  return (
    <div
      {...restProps}
      className={cn(
        "inline-flex shrink-0 items-center justify-center",
        containerClass,
        restProps.className,
      )}
    >
      <IntegrationLogo id={id} className="h-4/5 w-auto" />
    </div>
  );
}
