import React, { useEffect, useMemo, useRef, useState } from "react";

import { SubscriptionStatus } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import confirm from "@/components/elements/Confirm";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import Center from "@/components/elements/layout/Center";
import { useSubscriptionStatus } from "@/features/subscription";
import { useIsMobileSizeViewport } from "@/hooks/useIsMobileSizeViewport";
import usePrevious from "@/hooks/usePrevious";
import { IntegrationLogoBox, useIntegrationsMap } from "@/integrations";
import HRWithText from "@/pages/Setup/components/HRWithText";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount } from "@/providers/account";
import { Tab, Transition } from "@headlessui/react";
import { BookOpenIcon } from "@heroicons/react/24/outline";

import {
  SlideOver,
  SlideOverBody,
  SlideOverCloseButton,
  SlideOverHeader,
  useSlideOverContext,
} from "../../elements/SlideOver";
import {
  SyncsBlockedBookMeetingButton,
  syncsBlockedMessages,
} from "../SyncsBlocked";
import { UpgradeNowButton } from "../UpgradeNowButton";
import { Step, StepContextProvider, useSteps } from "../stepper/useSteps";
import { SubHeading } from "./components/Layout";
import { StepItem, StepList } from "./components/Steps";
import {
  DocumentationPane,
  useDocumentationPane,
} from "./components/documentation-viewer";
import {
  DataSourceStepsContextProvider,
  useDataSourceStepContext,
} from "./contexts/DataSourceStepsContext";
import { useDataSourceSlideOver } from "./hooks/useDataSourceSlideOver";
import { useValidInputProps } from "./hooks/useValidInputProps";
import { SetupCompleteStep } from "./steps/setup-complete";
import { SourceStepContainer } from "./steps/source";
import { SourceSettingsStepContainer } from "./steps/sourceSettings";
import { SyncSettingsStepContainer } from "./steps/syncSettings";
import { TablesStepContainer } from "./steps/tables";

export function NewDataSourceSlideOverContainer() {
  const { state, onClose } = useDataSourceSlideOver();
  return (
    <NewDataSourceSlideOver
      show={state.isOpen}
      onClose={onClose}
      connectionId={state.connectionId}
      integrationId={state.integrationId}
    />
  );
}

type NewDataSourceSlideOverProps = {
  integrationId?: string;
  connectionId?: string;
} & Pick<React.ComponentProps<typeof SlideOver>, "show" | "onClose">;

export function NewDataSourceSlideOver({
  integrationId,
  connectionId,
  ...slideOverProps
}: NewDataSourceSlideOverProps) {
  const toast = useToast();
  const validationResult = useValidInputProps({ integrationId, connectionId });

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (validationResult.isValidConnectionId === false) {
      toast(
        "Invalid connection",
        "The provided connection id was not valid",
        "error",
      );
    }
    if (validationResult.isValidIntegrationId === false) {
      toast(
        "Invalid integration",
        "The provided integration id was not valid",
        "error",
      );
    }
  }, [validationResult, toast]);

  const [isDirty, setIsDirty] = useState(false);

  const onCloseSlideOver = async (options?: { forceClose?: boolean }) => {
    if (!isDirty || options?.forceClose) {
      slideOverProps.onClose();
      return;
    }

    const confirmed = await confirm({
      title: "Unsaved Changes",
      message: "Are you sure you want to discard your changes?",
      type: "danger",
    });
    if (confirmed) {
      slideOverProps.onClose();
    }
  };

  return (
    <SlideOver
      size="lg"
      {...slideOverProps}
      initialFocus={searchInputRef}
      bgOverlay
      onClose={onCloseSlideOver}
    >
      {validationResult.isLoading ? (
        <Center className="h-full w-full">
          <LoadingSpinner />
        </Center>
      ) : (
        <DataSourceStepsContextProvider
          initialFocusRef={searchInputRef}
          onCancel={slideOverProps.onClose}
          onDirtyChange={setIsDirty}
          {...validationResult.validInputProps}
        >
          <DataSourceContainer {...validationResult.validInputProps} />
        </DataSourceStepsContextProvider>
      )}
    </SlideOver>
  );
}

function DataSourceContainer(
  props: ReturnType<typeof useValidInputProps>["validInputProps"],
) {
  const { integrationId, connectionHasSettings } = useDataSourceStepContext();
  const integrationsRegistry = useIntegrationsMap();
  const integration = integrationsRegistry.get(integrationId || "");

  const account = useCurrentAccount();

  const { setSize: setSlideOverSize, resetSize: resetSlideOverSize } =
    useSlideOverContext();

  const isMobileSizeViewport = useIsMobileSizeViewport();

  const { toggle: toggleDocumentationPane, paneProps } = useDocumentationPane(
    integration,
    {
      onOpen: () => {
        setSlideOverSize("3xl");
      },
      onClose: () => {
        resetSlideOverSize();
      },
    },
  );

  const steps = useMemo(() => {
    const steps: Step[] = [
      {
        id: "source",
        title: "Data source",
        component: SourceStepContainer,
      },
      {
        id: "source-settings",
        title: "Connection Settings",
        component: SourceSettingsStepContainer,
      },
      {
        id: "tables",
        title: "Data to sync",
        component: TablesStepContainer,
      },
      {
        id: "sync-settings",
        title: "Configure sync",
        component: SyncSettingsStepContainer,
      },
      {
        id: "setup-complete",
        title: "Setup Complete",
        component: SetupCompleteStep,
        isHidden: true,
      },
    ];
    // `connectionHasSettings` is `undefined` until a connection is selected settings info is loaded
    // therefore we test for an explicit `false` value to disable the step
    if (connectionHasSettings === false) {
      return steps.map((step) => {
        if (step.id === "source-settings") {
          return {
            ...step,
            isDisabled: true,
            tooltip: `"${integration?.name}" does not have any additional settings to configure`,
          };
        }
        return step;
      });
    }
    return steps;
  }, [integration, connectionHasSettings]);

  const stepContext = useSteps(steps, () => {
    if (props.connectionId) {
      return props.connectionHasSettings ? "source-settings" : "tables";
    }
    return 0;
  });

  const { currentStepIndex, setCurrentStepIndex } = stepContext;
  const previousStepIndex = usePrevious(currentStepIndex);

  useEffect(() => {
    if (steps[currentStepIndex].id === "setup-complete") {
      toggleDocumentationPane(false);
    }
  }, [currentStepIndex, steps, toggleDocumentationPane]);

  // Used to set transition classes based on direction
  const pagingDirection =
    previousStepIndex === undefined || previousStepIndex <= currentStepIndex
      ? "FORWARD"
      : "BACKWARD";

  return (
    <StepContextProvider {...stepContext}>
      <Tab.Group
        selectedIndex={currentStepIndex}
        onChange={(index) => {
          setCurrentStepIndex(index);
        }}
      >
        <div className="flex h-full w-full">
          <div className="relative flex flex-1 flex-col">
            <SlideOverCloseButton />
            <SlideOverHeader className="box-content flex basis-11 items-center pb-6">
              {integration == null ? (
                <span>Set up a data source</span>
              ) : (
                <div className="flex w-full items-center gap-3">
                  <IntegrationLogoBox id={integration.id} size="md" />
                  <div>
                    <SubHeading>Set up a data source</SubHeading>
                    <div className="flex">
                      <p>{integration.id}</p>
                    </div>
                  </div>
                  <div className="ml-auto self-end">
                    {!paneProps.isOpen && paneProps.data && (
                      <>
                        {isMobileSizeViewport ? (
                          <Button
                            as="a"
                            target="_blank"
                            href={paneProps.data.href}
                            variant="link"
                            icon={<BookOpenIcon />}
                            size="sm"
                          >
                            View setup guide
                          </Button>
                        ) : (
                          <Button
                            variant="link"
                            onClick={() => toggleDocumentationPane()}
                            icon={<BookOpenIcon />}
                            size="sm"
                          >
                            View setup guide
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </SlideOverHeader>
            {account.accountSettings.deactivateELTSyncs ? (
              <SlideOverBody>
                <SyncsBlockedMessage />
              </SlideOverBody>
            ) : (
              <div className="flex flex-1 flex-col overflow-hidden">
                <div className="flex flex-1">
                  <div className="flex flex-1 flex-col">
                    <Tab.List className="relative mx-6 py-6 text-sm">
                      <StepList>
                        {stepContext.steps.map((step) => (
                          <StepItem step={step} key={step.id} />
                        ))}
                      </StepList>
                    </Tab.List>
                    <SlideOverBody className="overflow-hidden p-0">
                      <Tab.Panels className="relative h-full w-full">
                        {stepContext.steps.map((step, i) => (
                          <Transition
                            show={i === currentStepIndex}
                            key={step.id}
                            enter="transition duration-500 ease-in-out"
                            enterFrom={
                              pagingDirection === "FORWARD"
                                ? "translate-x-full"
                                : "-translate-x-full"
                            }
                            enterTo="translate-x-0"
                            leave="transition duration-500 ease-in-out"
                            leaveFrom="translate-x-0"
                            leaveTo={
                              pagingDirection === "FORWARD"
                                ? "-translate-x-full"
                                : "translate-x-full"
                            }
                            className="absolute h-full w-full bg-white dark:bg-gray-800"
                          >
                            <Tab.Panel
                              key={step.id}
                              className="flex h-full w-full flex-col focus:outline-none"
                              static
                            >
                              <step.component />
                            </Tab.Panel>
                          </Transition>
                        ))}
                      </Tab.Panels>
                    </SlideOverBody>
                  </div>
                </div>
              </div>
            )}
          </div>
          <DocumentationPane {...paneProps} />
        </div>
      </Tab.Group>
    </StepContextProvider>
  );
}

function SyncsBlockedMessage() {
  const { status } = useSubscriptionStatus();
  return (
    <Center className="h-full w-full">
      <div className="flex flex-col items-center gap-4 text-muted-foreground">
        <h3 className="text-lg font-medium">
          {syncsBlockedMessages.ETL_SYNCS_BLOCKED_HEADING}
        </h3>
        {status === SubscriptionStatus.Freemium ? (
          <div className="flex flex-col items-center gap-4">
            <p>
              To keep using our paid features, upgrade to one of our paid plans.
            </p>
            <UpgradeNowButton source="new data source slide-over" />
            <HRWithText>Or</HRWithText>
            <SyncsBlockedBookMeetingButton />
          </div>
        ) : (
          <>
            <p>{syncsBlockedMessages.ETL_SYNCS_BLOCKED_BODY}</p>
            <SyncsBlockedBookMeetingButton />
          </>
        )}
      </div>
    </Center>
  );
}
