import { useMemo } from "react";

import { useListOrchestrationWorkflowsQuery } from "@/apollo/types";

export const useListOrchestrationWorkflows = (poll: boolean = true) => {
  const { data, loading } = useListOrchestrationWorkflowsQuery({
    pollInterval: poll ? 60_000 : undefined,
    skipPollAttempt: () => document.hidden,
  });

  return useMemo(() => {
    const workflows = data?.orchestrationWorkflows ?? [];

    return {
      workflows,
      loading,
    };
  }, [data?.orchestrationWorkflows, loading]);
};
