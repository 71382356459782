import { Alert, AlertIcon, AlertTitle } from "@/components/elements/Alert";
import { useIntegrationDocumentationUrl } from "@/integrations/documentation/useIntegrationDocumentation";
import { useMixpanel } from "@/monitoring/mixpanel";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

type Props = {
  integrationId: string;
};

const IPWhitelistInfo = (props: Props) => {
  const mixpanel = useMixpanel();

  const documentationUrl = useIntegrationDocumentationUrl(
    props.integrationId,
    false,
  );

  return (
    <Alert status="info" className="items-start">
      <AlertIcon />
      <div className="leading-normal">
        <AlertTitle className="mb-2">Whitelist WELD's IP pool</AlertTitle>
        <div className="space-y-2">
          <p>Requests from WELD will always come from the following IP pool:</p>
          <ul className="list-inside list-disc">
            <li>3.64.84.139</li>
            <li>3.65.119.169</li>
            <li>35.156.133.78</li>
          </ul>
          <p>
            Make sure to whitelist all three of these IP's within your network
            policies, SSH gateway server or the DB itself. If any updates to the
            list are ever scheduled to happen, you will be contacted by WELD via
            email.
            {documentationUrl && (
              <a
                href={documentationUrl}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  mixpanel.track("Connector Setup Documentation Viewed", {
                    integration_id: props.integrationId,
                  });
                }}
                className="ml-1 inline-flex items-center text-sm underline"
              >
                View full setup guide
                <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
              </a>
            )}
          </p>
        </div>
      </div>
    </Alert>
  );
};

export default IPWhitelistInfo;
