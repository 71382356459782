import classNames from "@/helpers/classNames";

type FieldLabelProps = React.ComponentProps<"label"> & {
  required?: boolean;
};

export default function FieldLabel({
  required,
  className,
  children,
  ...props
}: FieldLabelProps) {
  return (
    <label
      className={classNames(
        "mb-1 block text-xs font-semibold leading-6 dark:text-muted-foreground",
        className,
      )}
      {...props}
      htmlFor={props.htmlFor ?? props.id}
    >
      {children}
      {required && <span> *</span>}
    </label>
  );
}
