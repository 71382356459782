import { useMemo } from "react";

import {
  AvailableIntegrationFragment,
  IntegrationAbility,
} from "@/apollo/types";
import {
  hasAbility,
  useIntegrationFlagHelper,
  useIntegrations,
} from "@/integrations";

export function useFilterByDataWarehouseSupport(
  dataWarehouseIntegration: AvailableIntegrationFragment | null,
  integrations: AvailableIntegrationFragment[] | undefined,
) {
  const dataWarehouseHasExtractAbility = hasAbility(
    dataWarehouseIntegration,
    IntegrationAbility.EltDestination,
  );
  const dataWarehouseHasActivateAbility = hasAbility(
    dataWarehouseIntegration,
    IntegrationAbility.ActivateSource,
  );

  const dataWarehouseHasBothAbilities =
    dataWarehouseHasExtractAbility && dataWarehouseHasActivateAbility;

  return useMemo(() => {
    if (dataWarehouseIntegration == null) {
      return [];
    }
    return (integrations ?? [])
      .filter((i) => i.eltSource || i.activateDestination)
      .filter((i) => {
        if (dataWarehouseHasBothAbilities) {
          return true;
        }
        if (dataWarehouseHasExtractAbility) {
          return i.eltSource != null;
        }
        if (dataWarehouseHasActivateAbility) {
          return i.activateDestination != null;
        }
        return false;
      });
  }, [
    integrations,
    dataWarehouseIntegration,
    dataWarehouseHasBothAbilities,
    dataWarehouseHasExtractAbility,
    dataWarehouseHasActivateAbility,
  ]);
}

function useFilterByFeatureFlag(integrations: AvailableIntegrationFragment[]) {
  const integrationFlagHelper = useIntegrationFlagHelper();
  return useMemo(() => {
    return integrations.filter((i) => {
      const eltFeature = integrationFlagHelper.checkFlag(
        i.id,
        IntegrationAbility.EltSource,
      );
      const reverseEltFeature = integrationFlagHelper.checkFlag(
        i.id,
        IntegrationAbility.ActivateDestination,
      );
      return eltFeature !== "hide" || reverseEltFeature !== "hide";
    });
  }, [integrations, integrationFlagHelper]);
}

export function useAvailableIntegrations(
  dataWarehouseIntegration: AvailableIntegrationFragment | null,
) {
  const integrations = useIntegrations();
  const supportedIntegrations = useFilterByDataWarehouseSupport(
    dataWarehouseIntegration,
    integrations,
  );
  const enabledIntegrations = useFilterByFeatureFlag(supportedIntegrations);
  return enabledIntegrations;
}
