import { atom, useAtom } from "jotai";
import { useEffect, useRef } from "react";

import {
  GetTemplatesDocument,
  TemplatesEnum,
  useCreateTemplateMutation,
} from "@/apollo/types";
import { LoadingOverlay } from "@/components/elements/LoadingComponents";
import { SlideUp } from "@/components/elements/SlideUp";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount } from "@/providers/account";

import SelectedMetric from "./SelectedMetric";
import { TemplateProvider, useTemplate } from "./components/TemplatesProvider";

const coreMetricsOpenAtom = atom(false);

export const useCoreMetricsOpen = () => useAtom(coreMetricsOpenAtom);

export const CoreMetricsDrawer = () => {
  const [show, setShow] = useCoreMetricsOpen();
  return (
    <SlideUp onClose={() => setShow(false)} show={show}>
      <CoreMetricsDrawerContent />
    </SlideUp>
  );
};

function CoreMetricsDrawerContent() {
  const { marketingTemplate, loading } = useTemplate();

  const toast = useToast();

  const [createTemplate, { loading: creatingTemplate }] =
    useCreateTemplateMutation({
      onError: (e) => {
        toast(
          "Error configuring account",
          "Error configuring metrics for your account.",
          "error",
        );
      },
      refetchQueries: [GetTemplatesDocument],
      awaitRefetchQueries: true,
    });

  const currentAccount = useCurrentAccount();
  const triggered = useRef(false);
  useEffect(() => {
    if (loading || creatingTemplate || !!marketingTemplate) return;

    //max call 1 time..
    if (triggered.current) return;
    triggered.current = true;

    createTemplate({
      variables: {
        accountId: currentAccount.id,
        template: TemplatesEnum.Marketing,
      },
    });
  }, [
    createTemplate,
    creatingTemplate,
    currentAccount.id,
    loading,
    marketingTemplate,
  ]);
  return (
    <>
      {(loading || creatingTemplate) && <LoadingOverlay />}
      {marketingTemplate && (
        <TemplateProvider>
          <SelectedMetric />
        </TemplateProvider>
      )}
    </>
  );
}
