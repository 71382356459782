import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  GetRawViewsDocument,
  IntegrationAbility,
  useGetDwDirectoryItemsQuery,
  useGetDwDirectoryQuery,
  useImportDwDirectoryMutation,
} from "@/apollo/types";
import { PrimaryButton, SecondaryButton } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import { useReflexAccordionItemContext } from "@/components/elements/ReflexAccordion/ReflexAccordionItem";
import Select from "@/components/elements/Select";
import Tooltip from "@/components/elements/Tooltip";
import ImportIcon from "@/components/icons/ImportIcon";
import { DataSourceIcon } from "@/components/icons/outline";
import { useDataSourceSlideOver } from "@/components/modules/new-data-source-slideover";
import {
  ConnectorRecommendationItem,
  useConnectorRecommendations,
} from "@/features/connectors";
import { useFirstEltSyncedGuide } from "@/features/product-guide/guides/FirstEltSynced/useFirstEltSyncedGuide";
import { useArrowKeyFocusNavigation } from "@/hooks/useArrowKeyFocusNavigation";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount, useDataWarehouse } from "@/providers/account";
import { PlusIcon } from "@heroicons/react/24/outline";

import { useSchemaSidebar } from "../../ModelEditorStore";
import { FolderItemType, SidebarItemFactory } from "../SidebarFactory";
import { PaneActionButton } from "../components/PaneActionButton";
import {
  PaneEmptyState,
  PaneEmptyStateButton,
  PaneEmptyStateText,
} from "../components/PaneEmptyState";
import { PaneContent, PaneHeader } from "../components/SidebarPane";
import { useSidebarFolders } from "../useSidebarFolders";
import { useDataSourcesFolders } from "./useDataSourcesFolders";

export const DataSourcesPane = React.memo(function DataSourcesPaneMemo() {
  const dwh = useDataWarehouse();

  const { dataSourcesFolders, loading } = useDataSourcesFolders();
  const { openFolder } = useSidebarFolders();

  const { isOpen, toggle } = useReflexAccordionItemContext();

  useFirstEltSyncedGuide(dataSourcesFolders, (foldersAvailable) => {
    //Make sure to open the data sources pane!
    //also open the synced folder to make sure guide has it available!
    toggle(true);
    foldersAvailable.forEach((folder) => {
      openFolder(folder.id);
    });
  });

  const [isImporting, setIsImporting] = useState(false);
  const handleImport = useCallback(() => {
    setIsImporting(true);
  }, []);

  /**
   * Count of items that represent connected data sources.
   * Excludes imported views and imported demo data.
   */
  const hasConnectedData = dataSourcesFolders.reduce((acc, folder) => {
    for (const item of folder.children) {
      if (item.itemType === "view" && !item.rawView.syncId) continue;
      acc++;
    }
    return acc;
  }, 0);

  const showEmptyState = !loading && !hasConnectedData;

  const mixpanel = useMixpanel();

  const { onOpen } = useDataSourceSlideOver();
  return (
    <>
      <PaneHeader
        open={isOpen}
        toggle={toggle}
        actions={
          <>
            {!dwh.isManagedDataWarehouse && (
              <Tooltip content="Import data from data warehouse">
                <PaneActionButton
                  variant="outline"
                  onClick={() => {
                    handleImport();
                  }}
                  icon={<ImportIcon />}
                >
                  Import
                </PaneActionButton>
              </Tooltip>
            )}
            <Tooltip content="Connect new source">
              <PaneActionButton
                variant="solid"
                colorScheme="primary"
                onClick={() => {
                  onOpen();
                  mixpanel.track("Add Data Source Clicked", {
                    source: "sidebar",
                  });
                }}
                icon={<PlusIcon />}
                data-stonly="editor-sidebar__new-data-source"
              />
            </Tooltip>
          </>
        }
      >
        Data sources
      </PaneHeader>
      <PaneContent>
        {loading ? (
          <div className="flex items-center justify-center px-4 py-2">
            <LoadingSpinner />
          </div>
        ) : (
          <DataSourcesFolders folders={dataSourcesFolders} />
        )}
        {showEmptyState && <RawViewListEmpty />}
        <Modal isOpen={isImporting} onClose={() => setIsImporting(false)}>
          <ImportModalContent onClose={() => setIsImporting(false)} />
        </Modal>
      </PaneContent>
    </>
  );
});

const ImportModalContent = (props: { onClose: () => void }) => {
  const account = useCurrentAccount();
  const dataWarehouseConnectionId = account.dataWarehouseConnectionId ?? "";
  const { data, loading: firstLevelLoading } = useGetDwDirectoryQuery({
    variables: {
      input: {
        connectionId: dataWarehouseConnectionId,
      },
    },
  });

  const [selectedDirectory, setSelectedDirectory] = useState<string>("");

  const hasSecondLevelDirectories =
    !!selectedDirectory && !!data?.getDWDirectory.hasNext;

  const { data: secondLevelData, loading: secondLevelLoading } =
    useGetDwDirectoryQuery({
      variables: {
        input: {
          connectionId: dataWarehouseConnectionId,
          path: [selectedDirectory],
        },
      },
      skip: !hasSecondLevelDirectories,
    });

  const [selectedSecondLevelDirectory, setSelectedSecondLevelDirectory] =
    useState<string>("");

  const { schema, database } = useMemo(() => {
    if (selectedSecondLevelDirectory) {
      return {
        schema: selectedSecondLevelDirectory,
        database: selectedDirectory,
      };
    } else {
      return {
        schema: selectedDirectory,
      };
    }
  }, [selectedDirectory, selectedSecondLevelDirectory]);

  const path = [selectedDirectory, selectedSecondLevelDirectory].filter(
    (p) => !!p,
  );

  const [selectedTables, setSelectedTables] = useState<string[]>([]);
  const { data: tables, loading: tablesLoading } = useGetDwDirectoryItemsQuery({
    variables: {
      input: {
        connectionId: dataWarehouseConnectionId,
        path,
      },
    },
    skip:
      !selectedDirectory ||
      secondLevelLoading ||
      (hasSecondLevelDirectories && !selectedSecondLevelDirectory),
    onCompleted: (data) => {
      if (data?.getDWDirectoryItems.length) {
        setSelectedTables(
          data.getDWDirectoryItems.map(
            (item) => item.path[item.path.length - 1],
          ),
        );
      }
    },
  });

  useEffect(() => {
    setSelectedSecondLevelDirectory("");
    setSelectedTables([]);
  }, [selectedDirectory]);

  useEffect(() => {
    setSelectedTables([]);
  }, [selectedSecondLevelDirectory]);

  const toast = useToast();

  const [mutate, { loading }] = useImportDwDirectoryMutation({
    variables: {
      input: {
        connectionId: dataWarehouseConnectionId,
        schema: schema,
        database: database,
        tables: selectedTables,
      },
    },
    onCompleted(data) {
      data.importDWDirectory.forEach((item) => {
        if (item.error) {
          toast(
            `Couldn't import ${item.path[item.path.length - 1]}`,
            item.error,
            "warning",
          );
        }
      });
      props.onClose();
      setSelectedDirectory("");
      setSelectedSecondLevelDirectory("");
      setSelectedTables([]);
    },
    refetchQueries: [{ query: GetRawViewsDocument }],
  });

  const handleSave = useCallback(async () => {
    mutate();
  }, [mutate]);

  return (
    <>
      <ModalCloseButton />
      <ModalHeader>
        <h2 className="text-lg font-semibold">
          Import Directory and Tables from Data Warehouse
        </h2>
        <p className="mt-2 text-sm text-muted-foreground">
          Bring in directories and tables from your data warehouse to begin
          querying them easily in the SQL Editor.
        </p>
      </ModalHeader>
      <ModalBody className="flex flex-col gap-4">
        <Select
          placeholder="Select a data warehouse directory"
          options={
            data?.getDWDirectory?.children?.map((v) => ({
              value: v,
              label: v,
            })) ?? []
          }
          value={
            selectedDirectory && {
              value: selectedDirectory,
              label: selectedDirectory,
            }
          }
          isLoading={firstLevelLoading}
          onChange={(v: any) => setSelectedDirectory(v?.value ?? "")}
        />
        {hasSecondLevelDirectories && (
          <Select
            placeholder="Select a data warehouse directory"
            options={
              secondLevelData?.getDWDirectory?.children?.map((v) => ({
                value: v,
                label: v,
              })) ?? []
            }
            value={
              selectedSecondLevelDirectory && {
                value: selectedSecondLevelDirectory,
                label: selectedSecondLevelDirectory,
              }
            }
            isLoading={secondLevelLoading}
            onChange={(v: any) =>
              setSelectedSecondLevelDirectory(v?.value ?? "")
            }
          />
        )}
        {(!!tables?.getDWDirectoryItems || tablesLoading) && (
          <Select
            isMulti
            placeholder="Select tables to import"
            options={
              tables?.getDWDirectoryItems.map((v) => ({
                value: v.path[v.path.length - 1],
                label: v.path[v.path.length - 1],
              })) ?? []
            }
            isLoading={tablesLoading}
            value={selectedTables.map((v) => ({
              value: v,
              label: v,
            }))}
            onChange={(v: any) =>
              setSelectedTables(v?.map((val: any) => val.value) ?? [])
            }
          />
        )}
      </ModalBody>
      <ModalFooter className="flex-row-reverse justify-start gap-4">
        <PrimaryButton
          onClick={() => handleSave()}
          className="flex-1"
          isLoading={loading}
          disabled={!selectedTables.length}
        >
          Import
        </PrimaryButton>
        <SecondaryButton onClick={() => props.onClose()} className="flex-1">
          Cancel
        </SecondaryButton>
      </ModalFooter>
    </>
  );
};

const RawViewListEmpty = () => {
  const { onOpen } = useDataSourceSlideOver();
  const recommendations = useConnectorRecommendations({
    ability: IntegrationAbility.EltSource,
    limit: 4,
  });
  return (
    <PaneEmptyState>
      <div className="flex flex-wrap items-center justify-center gap-2">
        {recommendations.map((integration) => (
          <ConnectorRecommendationItem
            key={integration.id}
            size="sm"
            integration={integration}
            onClick={() => onOpen({ integrationId: integration.id })}
          />
        ))}
      </div>
      <PaneEmptyStateText>
        Connect to over 150 data sources and start exploring your data.
      </PaneEmptyStateText>
      <PaneEmptyStateButton icon={<DataSourceIcon />} onClick={() => onOpen()}>
        <span className="truncate">Connect data source</span>
      </PaneEmptyStateButton>
    </PaneEmptyState>
  );
};

const DataSourcesFolders: React.FC<{
  folders: FolderItemType[];
}> = (props) => {
  const { openFolders, toggleFolder } = useSidebarFolders();
  const [highlightedId, setHighlightedId] = useState("");
  const containerRef = useRef<HTMLDivElement>(null);
  const currentHightlightedRef = useRef<HTMLDivElement>(null);

  useArrowKeyFocusNavigation(containerRef, currentHightlightedRef);

  const [schemaSidebarId] = useSchemaSidebar();

  useEffect(() => {
    const currentContainerRef = containerRef.current;
    if (!highlightedId || !currentContainerRef) return;

    const enterDownListener = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        currentHightlightedRef.current?.click();
      }
    };

    currentContainerRef.addEventListener("keydown", enterDownListener);
    return () =>
      currentContainerRef.removeEventListener("keydown", enterDownListener);
  }, [highlightedId]);

  return (
    <div
      className="flex select-none flex-col"
      onBlur={() => setHighlightedId("")}
    >
      <div className="max-h-full grow" ref={containerRef}>
        {props.folders.map((item) => {
          return (
            <SidebarItemFactory
              level={1}
              key={item.id}
              item={item}
              onFocus={(id) => {
                setHighlightedId(id);
              }}
              openItems={openFolders}
              onToggleFolder={toggleFolder}
              highlightedId={highlightedId}
              selectedId={schemaSidebarId ?? undefined}
              setHighlightedId={setHighlightedId}
              ref={currentHightlightedRef}
            />
          );
        })}
      </div>
    </div>
  );
};
