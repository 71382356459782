import { ComponentProps } from "react";

import { FeatureGuard } from "@/components/elements/FeatureGuard";
import { Heading, TextMuted } from "@/components/elements/Typography";
import { UpgradeNowButton } from "@/components/modules/UpgradeNowButton";
import { useCurrentPlan } from "@/features/subscription";
import cn from "@/helpers/classNames";
import { isCustomConnector } from "@/integrations";

export function useCustomConnectorFeatureGuard() {
  const { features, loading } = useCurrentPlan();
  const isEnabled = features.customConnectorEnabled;
  return {
    isEnabled,
    isLoading: loading,
  };
}

export function CustomConnectorFeatureGuard(
  props: {
    integrationId: string;
    children: React.ReactNode;
  } & Pick<ComponentProps<typeof FeatureGuard>, "fallback">,
) {
  const { isEnabled, isLoading } = useCustomConnectorFeatureGuard();
  return (
    <FeatureGuard
      test={!isCustomConnector(props.integrationId) || isLoading || isEnabled}
      fallback={props.fallback}
    >
      {props.children}
    </FeatureGuard>
  );
}

export function CustomConnectorTeaser(props: ComponentProps<"div">) {
  return (
    <div {...props} className={cn("flex flex-col gap-4", props.className)}>
      <Heading className="text-xl">Connect to any data source</Heading>
      <TextMuted className="text-sm leading-snug">
        Custom Connectors let you create and host your own connections to data
        sources or APIs. WELD handles the rest - scheduling, triggering,
        schemas, and data loading.{" "}
        <a
          href="https://weld.app/docs/custom-connector"
          target="_blank"
          rel="noreferrer"
          className="text-blue-500 underline"
        >
          Learn more
        </a>{" "}
        about Custom Connectors
      </TextMuted>
      <div className="flex w-full flex-col gap-4 text-center">
        <span>The Custom Connector is available on the Premium plan.</span>
        <UpgradeNowButton
          source="feature guard - custom connector"
          className="w-full"
        />
      </div>
    </div>
  );
}
