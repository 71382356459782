import { Helmet } from "react-helmet-async";

import { LocationGenerics } from "@/routes";
import { useMatches } from "@tanstack/react-location";

export default function Title() {
  const matches = useMatches<LocationGenerics>();
  const breadcrumbs = matches
    .filter((x) => x.route.meta?.breadcrumb)
    .map((x) => x.route.meta?.breadcrumb(x.params))
    .filter((x) => typeof x === "string")
    .join(" / ");
  return (
    <Helmet>
      <title>{breadcrumbs} | WELD</title>
    </Helmet>
  );
}
