import { useFindOneSyncQuery } from "@/apollo/types";
import { IntegrationLogo } from "@/integrations";

export function ReverseEltSyncBreadcrumb(props: { syncId: string }) {
  const { syncId } = props;
  const { data, loading } = useFindOneSyncQuery({
    variables: { syncId },
  });

  if (loading) return null;
  if (data == null) return null;

  const { destinationIntegrationId, primaryObject } = data.findOneSync;
  return (
    <div className="flex items-center gap-2">
      <IntegrationLogo id={destinationIntegrationId} className="h-4" />
      <span>{primaryObject}</span>
    </div>
  );
}
