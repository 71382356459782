import { isEqual } from "lodash";
import { useMemo } from "react";
import { FieldError } from "react-hook-form";

import { OrchestrationSchedulerType } from "@/apollo/types";
import { PrimaryButton, SecondaryButton } from "@/components/elements/Button";
import { CronDisplayReadable } from "@/components/elements/CronDisplayReadable";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import ListboxDropdown, {
  DropdownItem,
} from "@/components/elements/input/ListboxDropdown";
import { useListOrchestrationWorkflows } from "@/hooks/useListOrchestrationWorkflows";
import {
  useModelEditorDispatch,
  useModelEditorState,
} from "@/pages/ModelTool/ModelEditorStore";
import { useCurrentModel } from "@/pages/ModelTool/hooks/useCurrentModel";
import { useUpdateMaterialization } from "@/pages/ModelTool/hooks/usePublishModel";

import { OrchestrationIcon } from "../icons/outline";

type OrchestrationConfig = {
  orchestrationScheduler?: OrchestrationSchedulerType | undefined;
  orchestrationWorkflowId?: string | undefined;
};

export const useSchedulerOptions = () => {
  const { workflows } = useListOrchestrationWorkflows(false);

  const scheduleOptions: DropdownItem<OrchestrationConfig>[] = useMemo(() => {
    const options: DropdownItem<OrchestrationConfig>[] = [
      ...workflows.map((workflow) => {
        return {
          id: workflow.id,
          title: workflow.name,
          description: <CronDisplayReadable cron={workflow.cronExpression} />,
          icon: <OrchestrationIcon className="h-6 w-6 flex-none" />,
          value: {
            orchestrationScheduler: OrchestrationSchedulerType.Global,
            orchestrationWorkflowId: workflow.id,
          },
        };
      }),
      {
        id: "no-schedule",
        title: "No scheduler",
        description: "Do not run model tests as part of an orchestration",
        icon: <div className="h-4 w-4 flex-none rounded-full bg-gray-200" />,
        value: {
          orchestrationScheduler: undefined,
          orchestrationWorkflowId: undefined,
        },
      },
    ];

    return options;
  }, [workflows]);
  return scheduleOptions;
};

export const ModelTestScheduleSelectorModal = (props: {
  isShowing: boolean;
  handleClose: () => void;
}) => {
  const scheduleOptions = useSchedulerOptions();
  const currentModel = useCurrentModel();
  const state = useModelEditorState();
  const dispatch = useModelEditorDispatch();

  const selectedOption = useMemo(() => {
    const config: OrchestrationConfig = {
      orchestrationScheduler: state.orchestrationScheduler,
      orchestrationWorkflowId: state.orchestrationWorkflowId,
    };
    return scheduleOptions.find((o) => isEqual(o.value, config));
  }, [
    state.orchestrationScheduler,
    state.orchestrationWorkflowId,
    scheduleOptions,
  ]);

  const { handleUpdateMaterialization, loading } = useUpdateMaterialization(
    currentModel?.id,
  );

  //Don't display the selecter if there are no options to select
  if (scheduleOptions.length < 1) return null;
  if (!currentModel) return null;

  const handleUpdateScheduler = (updated: OrchestrationConfig) => {
    dispatch({
      type: "orchestration_scheduler_changed",
      payload: updated,
    });
  };

  const hasChanged =
    state.materializationType !== currentModel.materializationType ||
    state.materializationSchedule !==
      (currentModel.materializationSchedule || undefined) ||
    state.orchestrationScheduler !==
      (currentModel.orchestrationScheduler || undefined) ||
    state.orchestrationWorkflowId !== currentModel.orchestrationWorkflow?.id;

  return (
    <Modal onClose={props.handleClose} isOpen={props.isShowing}>
      <ModalHeader>Edit schedule for model tests</ModalHeader>
      <ModalBody>
        <ListboxDropdown<OrchestrationConfig>
          options={scheduleOptions}
          selected={selectedOption}
          onSelect={(option) => {
            if (option.value) handleUpdateScheduler(option.value);
          }}
          placeholder="Select orchestration the tests will run in."
        />
      </ModalBody>
      <ModalFooter className="flex-row-reverse gap-4">
        <PrimaryButton
          className="flex-1"
          onClick={async () => {
            if (loading || !hasChanged) return;
            await handleUpdateMaterialization({
              id: currentModel.id,
              materializationType: state.materializationType,
              materializationSchedule: state.materializationSchedule,
              orchestrationScheduler: state.orchestrationScheduler,
              orchestrationWorkflowId: state.orchestrationWorkflowId,
            });
            props.handleClose();
          }}
          isDisabled={!hasChanged}
          isLoading={loading}
          loadingText="Updating ..."
        >
          Update
        </PrimaryButton>
        <SecondaryButton className="flex-1" onClick={props.handleClose}>
          Cancel
        </SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};

const ValidationMessages: Record<string, string> = {
  required: "This field is required",
};

export const ValidationErrorDisplay = (props: { error: FieldError }) => {
  const errorMsg = ValidationMessages[props.error.type];

  return (
    <div className="text-xs text-red-500">{errorMsg || props.error.type}</div>
  );
};
