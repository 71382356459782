import {
  AvailableIntegrationFragment,
  DevStatus,
  IntegrationAbility,
} from "@/apollo/types";

export function createEmptyIntegration(
  id: string,
  name: string,
  abilities: IntegrationAbility[],
) {
  const int: AvailableIntegrationFragment = {
    abilities,
    id,
    name,
    baseId: id,
    categories: [],
    meetsPlanRequirement: true,
    isUtilityConnector: false,
    logo: {
      default: undefined,
    },
  };
  if (abilities.includes(IntegrationAbility.EltSource)) {
    int.eltSource = {
      status: DevStatus.None,
      tags: [],
    };
  }
  if (abilities.includes(IntegrationAbility.ActivateDestination)) {
    int.activateDestination = {
      status: DevStatus.None,
      tags: [],
    };
  }
  return int;
}
