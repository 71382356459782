import { useMemo, useRef } from "react";

import { IntegrationAbility } from "@/apollo/types";

import { ConnectorOption } from "./createConnectorOption";

const createAbilityFilterFn = (
  ability: IntegrationAbility | null | undefined,
) => {
  return (x: ConnectorOption) => {
    if (ability == null) return true;
    return x.abilities.some((a) => a === ability);
  };
};

function escapeRegex(input: string) {
  return input.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&");
}

const createSearchTextFilterFn = (searchText: string | null | undefined) => {
  return (x: ConnectorOption) => {
    if (searchText == null || searchText.trim().length === 0) return true;
    return (
      searchByText(x, searchText) || filterOnFileIntegrations(x, searchText)
    );
  };
};

const searchByText = (option: ConnectorOption, searchText: string) => {
  const searchTokens = escapeRegex(searchText)
    .split(/\s+/)
    .filter((x) => x.trim().length > 0);
  const regex = new RegExp(searchTokens.join("|"), "gi");
  return regex.test(option.label);
};

export function useFilterConnectors(
  list: ConnectorOption[],
  abilityFilter?: IntegrationAbility | null,
  searchText?: string | null,
  customSearchFilterFn?: (
    searchText: string | null | undefined,
    defaultFilterFn: ReturnType<typeof createSearchTextFilterFn>,
  ) => ReturnType<typeof createSearchTextFilterFn>,
) {
  const customSearchFilterFnRef = useRef(customSearchFilterFn);
  return useMemo(() => {
    const defaultSearchFilterFn = createSearchTextFilterFn(searchText);
    const filters = [
      // Filter on ability
      createAbilityFilterFn(abilityFilter),
      // Filter on search text
      typeof customSearchFilterFnRef.current === "function"
        ? customSearchFilterFnRef.current(searchText, defaultSearchFilterFn)
        : defaultSearchFilterFn,
    ];
    return list.filter((x) => {
      return filters.every((fn) => fn(x));
    });
  }, [list, abilityFilter, searchText]);
}

//Connector specific special text filter
//eg. JSON / CSV / EXCEL
const specialConnectorSupport = [
  {
    searchTerms: ["csv", ".csv"],
    integrationIds: [
      "google-drive",
      "google-drive__csv",
      "aws-s3",
      "email",
      "ftp",
      "sftp",
      "onedrive",
      "onedrive__csv",
      "sharepoint",
      "sharepoint__csv",
    ],
  },
  {
    searchTerms: ["json", ".json"],
    integrationIds: ["aws-s3"],
  },
  {
    searchTerms: ["parquet"],
    integrationIds: ["aws-s3"],
  },
  {
    searchTerms: ["excel", ".xls", ".xlsx", ".xlsm", ".xsl", ".xslx"],
    integrationIds: [
      "google-drive",
      "google-drive__excel",
      "onedrive",
      "onedrive__excel",
      "sharepoint",
      "sharepoint__excel",
      "excel",
    ],
  },
];

function filterOnFileIntegrations(
  option: ConnectorOption,
  searchText: string | null | undefined,
) {
  if (!searchText) {
    return false;
  }
  return specialConnectorSupport.some((support) => {
    return (
      support.integrationIds.includes(option.value) &&
      support.searchTerms.some((text) =>
        text.includes(searchText.toLowerCase()),
      )
    );
  });
}
