import { conformsTo, isString } from "lodash";
import { useEffect, useState } from "react";

import {
  AvailableIntegrationFragment,
  useGetSetupGuideUrlQuery,
} from "@/apollo/types";

export const useIntegrationDocumentation = (
  integrationId: string | undefined,
) => {
  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    if (!integrationId) {
      setContent(null);
      return;
    }

    const fetchContent = async () => {
      try {
        const response = await fetch(
          `https://weld.app/docs/${integrationId}.md`,
        );
        if (!response.ok) {
          setContent(null);
          return;
        }
        const text = await response.text();
        setContent(text);
      } catch (error) {
        setContent(null);
      }
    };
    fetchContent();
  }, [integrationId]);

  return content;
};

export const useIntegrationDocumentationResource = (
  integration?: Pick<AvailableIntegrationFragment, "id" | "aliasMetadata">,
  type?: "destination",
) => {
  const [data, setData] = useState<{
    integrationId: string;
    href: string;
    embedHref: string;
    title: string;
    setupSectionIds?: {
      etl?: string;
      reverseEtl?: string;
    };
  } | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchDocumentationInfo = async (integrationId: string) => {
      setLoading(true);
      try {
        const normalizedIntegrationId = integrationId
          .toLowerCase()
          .replace(/-v\d+$/, ""); // Remove version suffix, i.e. forex-v2 => forex
        const response = await fetch(
          `https://weld.app/api/docs/${normalizedIntegrationId}${type ? `?type=${type}` : ""}`,
        );
        if (!response.ok) {
          return null;
        }
        const data = await response.json();
        if (
          !conformsTo(data, {
            integrationId: isString,
            href: isString,
            embedHref: isString,
            title: isString,
          })
        ) {
          // eslint-disable-next-line no-console
          console.error("Invalid response from docs API");
          return null;
        }
        return data as {
          integrationId: string;
          href: string;
          embedHref: string;
          title: string;
        };
      } catch (error) {
        return null;
      } finally {
        setLoading(false);
      }
    };

    async function resolveDocumentationResource(
      integration: Pick<AvailableIntegrationFragment, "id" | "aliasMetadata">,
    ) {
      const primary = await fetchDocumentationInfo(integration.id);
      if (primary !== null) {
        return primary;
      }
      if (integration.aliasMetadata) {
        return await fetchDocumentationInfo(integration.aliasMetadata.id);
      }
      return null;
    }

    async function loadDocumentationResource(
      integration: Pick<AvailableIntegrationFragment, "id" | "aliasMetadata">,
    ) {
      const resource = await resolveDocumentationResource(integration);
      if (resource) {
        setData(resource);
      }
    }

    setData(null);
    if (integration) {
      loadDocumentationResource(integration);
    }
  }, [integration, type]);

  return {
    data,
    loading,
  };
};

export const useIntegrationDocumentationUrl = (
  integrationId: string | undefined,
  isDatawarehouse: boolean,
) => {
  const { data } = useGetSetupGuideUrlQuery({
    variables: {
      integrationId: integrationId ?? "",
      isDatawarehouse: isDatawarehouse,
    },
    skip: !integrationId,
  });

  return data?.getSetupGuideUrl ?? null;
};
