import dayjs from "dayjs";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";

import { PlanName } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import {
  WorkspaceAlert,
  WorkspaceAlertResolver,
} from "@/components/modules/AppBanners/useWorkspaceAlerts";
import { formatPlanName } from "@/features/billing";
import { useCurrentPlan } from "@/features/subscription";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useMixpanel } from "@/monitoring/mixpanel";
import { createStorageKey } from "@/utils/storage";

import { CoreProPlanDiscontinuationDialog } from "../shared/ui/CoreProPlanDiscontinuationDialog";
import { discontinuationDate, getNextDismissedUntil } from "../shared/utils";

const bannerAtom = atomWithStorage<{
  dismissedUntil: number;
}>(createStorageKey("core-pro-plan-discontinuation-banner-v2"), {
  dismissedUntil: 0,
});

export const useCoreProPlanDiscontinuationResolver: WorkspaceAlertResolver =
  () => {
    const weldPlan = useCurrentPlan();
    const [state, setState] = useAtom(bannerAtom);
    const { showCoreProPlanDiscontinuationAnnouncement } = useFlags();

    const alerts = useMemo<WorkspaceAlert[]>(() => {
      const dismissHandler = () => {
        const nextDismissedUntil = getNextDismissedUntil(
          dayjs(),
          discontinuationDate,
        ).valueOf();
        setState({ dismissedUntil: nextDismissedUntil });
      };

      let message = `The ${formatPlanName(weldPlan.name)} plan will be discontinued on May 1, 2025.`;
      let ctaText = "Learn more about your options";

      const daysLeft = Math.max(
        0,
        Math.ceil(
          dayjs.utc(discontinuationDate).diff(dayjs.utc(), "days", true),
        ),
      );
      if (daysLeft <= 7) {
        message = `The ${formatPlanName(weldPlan.name)} plan will be discontinued in ${daysLeft} days on May 1, 2025.`;
        ctaText = "Act now to explore alternatives";
      }

      return [
        {
          id: "core-pro-plan-discontinuation",
          alwaysShow: true,
          status: "info",
          message,
          action: (
            <LearnMoreButton
              showDialogInitially={state.dismissedUntil === 0}
              onDialogClose={dismissHandler}
            >
              {ctaText}
            </LearnMoreButton>
          ),
          isDismissable: true,
          onDismiss: dismissHandler,
        },
      ];
    }, [setState, weldPlan.name, state.dismissedUntil]);

    if (
      ![PlanName.Core, PlanName.Pro].includes(weldPlan.name) ||
      !showCoreProPlanDiscontinuationAnnouncement
    ) {
      return [];
    }
    if (state.dismissedUntil > Date.now()) {
      return [];
    }
    return alerts;
  };

function LearnMoreButton(props: {
  showDialogInitially?: boolean;
  onDialogClose?: () => void;
  children: React.ReactNode;
}) {
  const disclosure = useDisclosure({
    defaultState: { isOpen: props.showDialogInitially || false },
  });
  const mixpanel = useMixpanel();
  return (
    <>
      <Button
        onClick={() => {
          disclosure.onOpen();
          mixpanel.track("Show Core_Pro Plan Discontinuation Dialog Clicked", {
            location: "workspace-banner",
          });
        }}
        colorScheme="primary"
        variant="solid"
        size="xs"
        className="tracking-wide"
        style={{ fontSize: "0.94em" }}
      >
        {props.children}
      </Button>
      <CoreProPlanDiscontinuationDialog
        {...disclosure}
        onClose={() => {
          props.onDialogClose?.();
          disclosure.onClose();
        }}
      />
    </>
  );
}
