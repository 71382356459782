import { ReactComponent as EdIcon } from "@/assets/ed.svg";
import { ReactComponent as DestinationIcon } from "@/assets/icons/destination.svg";
import {
  BellIcon,
  Cog8ToothIcon,
  CommandLineIcon,
  CubeIcon,
  GlobeAltIcon,
  LifebuoyIcon,
  PlayCircleIcon,
} from "@heroicons/react/24/outline";

import { ReactComponent as OrchestrationIcon } from "./orchestration_icon.svg";

export const DataSourceIcon = CubeIcon;
export const ReverseEtlIcon = PlayCircleIcon;
export const EditorIcon = CommandLineIcon;
export const AiAssistantIcon = EdIcon;
export const SettingsIcon = Cog8ToothIcon;
export const AlertsIcon = BellIcon;
export const HelpIcon = LifebuoyIcon;
export const WebhookIcon = GlobeAltIcon;
export { DestinationIcon, OrchestrationIcon };
