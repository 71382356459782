import { useThrottledCallback } from "use-debounce";

import {
  Notification,
  SyncJobStatus,
  useEltSyncSnapshotStateQuery,
} from "@/apollo/types";
import { useEltSyncNotifications } from "@/features/notifications";
import { useEltSync } from "@/hooks/useSync";
import { useSocketEvent } from "@/socket/SocketContext";

export enum ELTSyncStatus {
  NOT_STARTED = "NOT_STARTED",
  PAUSED = "PAUSED",
  NO_SYNC = "No sync yet",
  ALL_SYNCED = "ALL_SYNCED",
  PARTIALLY_SYNCED = "PARTIALLY_SYNCED",
  ALL_FAILING = "ALL_FAILING",
  PARTIAL_FAILING = "PARTIAL_FAILING",
  NEEDS_USER_INTERACTION = "NEEDS_USER_INTERACTION",
}

function useNotifications(eltSync: ReturnType<typeof useEltSync>["eltSync"]) {
  const notificationsQuery = useEltSyncNotifications(eltSync ? [eltSync] : []);

  const syncNotifications = [
    ...notificationsQuery.notifications.account,
    ...notificationsQuery.notifications.connector,
    ...notificationsQuery.notifications.sync,
  ].filter((x) => x.isLock);

  const sourceStreamNotifications =
    notificationsQuery.notifications.eltStream.filter((x) => x.isLock);

  return {
    loading: notificationsQuery.loading,
    syncNotifications,
    sourceStreamNotifications,
  };
}

/*
 * This hook is used to get the status of an eltSync
 * It uses the notifications to see if the sync is failing
 * It also checks if the sync does not have any sync history yet
 */
export const useEltSyncStatus = (
  eltSyncId: string,
): {
  status?: ELTSyncStatus;
  nrFailingStreams?: number;
  loading?: boolean;
  notifications?: Notification[];
} => {
  const { eltSync } = useEltSync(eltSyncId);

  const {
    syncNotifications,
    sourceStreamNotifications,
    loading: notificationsLoading,
  } = useNotifications(eltSync);

  const {
    data: snapshotData,
    refetch,
    loading: sourceStreamStatesLoading,
  } = useEltSyncSnapshotStateQuery({
    variables: {
      syncId: eltSyncId,
    },
    skip: !eltSync,
  });

  const throttledRefetch = useThrottledCallback(refetch, 20_000, {
    leading: true,
    trailing: true,
  });

  useSocketEvent("elt-sync:updated", {
    onMessage(resp) {
      if (resp.payload.syncId === eltSyncId) {
        throttledRefetch();
      }
    },
  });

  const sourceStreamStates =
    snapshotData?.eltSyncSnapshotState.sourceStreams || [];

  if (notificationsLoading || sourceStreamStatesLoading) {
    return { loading: true };
  }

  if (eltSync?.status === "NOT_STARTED") {
    return { status: ELTSyncStatus.NOT_STARTED };
  }

  if (eltSync?.status === "PAUSED") {
    return { status: ELTSyncStatus.PAUSED };
  }

  if (syncNotifications.length > 0) {
    return {
      status: ELTSyncStatus.NEEDS_USER_INTERACTION,
      notifications: syncNotifications,
    };
  }

  const pastRuns = sourceStreamStates.filter((x) => x.latestSync != null);
  if (!pastRuns.length) {
    return { status: ELTSyncStatus.NO_SYNC };
  }

  if (
    pastRuns.length &&
    eltSync?.sourceStreams.length &&
    pastRuns.length < eltSync?.sourceStreams.length
  ) {
    return { status: ELTSyncStatus.PARTIALLY_SYNCED };
  }

  const hasFailingSyncHistory = sourceStreamStates.some(
    (x) => x.latestSync?.status === SyncJobStatus.Failed,
  );

  if (syncNotifications.length === 0 && !hasFailingSyncHistory) {
    return { status: ELTSyncStatus.ALL_SYNCED };
  }

  const allTablesFailed =
    sourceStreamNotifications.length === eltSync?.sourceStreams.length;
  if (allTablesFailed) {
    return {
      status: ELTSyncStatus.ALL_FAILING,
      nrFailingStreams: eltSync?.sourceStreams.length,
    };
  }

  return {
    status: ELTSyncStatus.PARTIAL_FAILING,
    nrFailingStreams: sourceStreamNotifications.length,
  };
};
