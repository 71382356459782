import { useMemo } from "react";

import { NotificationEntityType, useNotificationsQuery } from "@/apollo/types";

export const useNotifications = () => {
  const { data, loading } = useNotificationsQuery({
    fetchPolicy: "network-only",
    pollInterval: 60_000,
    skipPollAttempt: () => document.hidden,
  });

  const notifications = useMemo(() => {
    if (!data) return [];
    return data?.notifications;
  }, [data]);

  return { notifications, loading };
};

export const useAccountNotifications = () => {
  const { notifications, loading } = useNotifications();
  return {
    notifications: notifications.filter(
      (x) => x.entityType === NotificationEntityType.Account,
    ),
    loading,
  };
};
