import React from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import * as routeHelpers from "router/routeHelpers";

import { ReactComponent as DestinationIcon } from "@/assets/icons/destination.svg";
import { PrimaryButton } from "@/components/elements/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import cn from "@/helpers/classNames";
import { useDisclosure } from "@/hooks/useDisclosure";
import {
  DestinationsIntegrationsProvider,
  useIntegration,
} from "@/integrations";
import { AccountType, useUserAccounts } from "@/providers/UserProvider";
import { useCurrentAccountIdValue } from "@/providers/account";
import { LinkToWorkspace } from "@/router";
import { Popover, Transition } from "@headlessui/react";
import { CheckIcon, PlusIcon } from "@heroicons/react/24/outline";

export function WorkspaceSwitcher({
  children,
  allowCreateDestination,
}: {
  children:
    | React.ReactElement
    | ((currentAccount?: AccountType) => React.ReactElement);
  allowCreateDestination?: boolean;
}) {
  const accounts = useUserAccounts();
  const currentAccountId = useCurrentAccountIdValue();
  const createDestinationDisclosure = useDisclosure();

  const currentAccount = React.useMemo(
    () => accounts.find((x) => x.id === currentAccountId),
    [accounts, currentAccountId],
  );

  let [referenceElement, setReferenceElement] =
    React.useState<HTMLDivElement | null>(null);
  let [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(
    null,
  );
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
    strategy: "absolute",
  });

  const sortedAccounts = React.useMemo(() => {
    return accounts.slice().sort((a, b) => a.name.localeCompare(b.name));
  }, [accounts]);

  if (!sortedAccounts.length) {
    return null;
  }
  return (
    <DestinationsIntegrationsProvider>
      <Popover>
        <Popover.Button as="div">
          {React.cloneElement(
            typeof children === "function"
              ? children(currentAccount)
              : React.Children.only(children),
            {
              ref: setReferenceElement,
              role: "button",
            },
          )}
        </Popover.Button>
        {createPortal(
          <Transition
            as={React.Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Panel
              ref={(elm) => setPopperElement(elm)}
              style={styles.popper}
              {...attributes.popper}
              className="z-50 max-h-screen w-64 rounded border bg-white shadow-lg dark:border-gray-700 dark:bg-gray-800"
            >
              <div className="flex h-full flex-col">
                <div className="flex items-center gap-2 border-b px-3 py-1 text-sm font-medium">
                  Destinations
                </div>
                <div className="max-h-96 grow overflow-auto">
                  {sortedAccounts.map((account) => {
                    const selected = currentAccountId === account.id;
                    return (
                      <LinkToWorkspace
                        key={account.slug}
                        slug={account.slug}
                        className={cn(
                          "group block w-full text-left text-black hover:bg-blue-500 hover:text-white dark:text-white dark:hover:bg-blue-500",
                          {
                            "bg-gray-200 text-black dark:bg-gray-800 dark:text-white":
                              currentAccountId === account.id,
                          },
                        )}
                      >
                        <div className="flex items-center justify-between space-x-1 px-3 py-2">
                          <span className="flex flex-col">
                            <span>{account.name}</span>
                            <WorkspaceDataWarehouse account={account} />
                          </span>
                          {selected && <CheckIcon className="w-4 flex-none" />}
                        </div>
                      </LinkToWorkspace>
                    );
                  })}
                </div>
                {allowCreateDestination && (
                  <button
                    onClick={() => createDestinationDisclosure.onOpen()}
                    className="block w-full border-t text-left hover:bg-blue-500 hover:text-white"
                  >
                    <div className="flex items-center space-x-1 px-3 py-2">
                      <PlusIcon className="w-3 dark:text-white" />
                      <span className="text-xs font-semibold">
                        Create new destination
                      </span>
                    </div>
                  </button>
                )}
              </div>
            </Popover.Panel>
          </Transition>,
          document.body,
        )}
      </Popover>
      <CreateDestinationDialog {...createDestinationDisclosure} />
    </DestinationsIntegrationsProvider>
  );
}

const WorkspaceDataWarehouse = (props: { account: AccountType }) => {
  const dataWarehouseIntegration = useIntegration(
    props.account.dataWarehouse?.integrationId,
  );

  const renderDataWarehouseName = (
    dwIntegration: NonNullable<typeof dataWarehouseIntegration>,
  ) => {
    return (
      dwIntegration.name +
      (dwIntegration.id === "weld-bigquery" ? " (managed by WELD)" : "")
    );
  };

  return (
    <div className="flex flex-row items-center">
      <span
        className={`overflow-hidden overflow-ellipsis whitespace-nowrap text-xs text-gray-500 group-hover:text-gray-200`}
      >
        {dataWarehouseIntegration == null
          ? "Data warehouse not configured"
          : renderDataWarehouseName(dataWarehouseIntegration)}
      </span>
    </div>
  );
};

function CreateDestinationDialog(props: ReturnType<typeof useDisclosure>) {
  return (
    <Modal {...props}>
      <ModalCloseButton />
      <ModalHeader className="flex items-center gap-3">
        <DestinationIcon className="h-6 w-6" />
        <span>Create new destination</span>
      </ModalHeader>
      <ModalBody>
        <p className="mb-3">
          To proceed with creating a new destination, you'll need to set up a
          new account.
        </p>
        <p>
          Click on the "Create Account" button below and follow the instructions
          to create a new account with a destination.
        </p>
      </ModalBody>
      <ModalFooter>
        <PrimaryButton
          onClick={() => window.open(routeHelpers.createWorkspaceRoute())}
        >
          Create account
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  );
}
